import { UpdateUser } from "../../interface/user";
import { endpoints, commonHeader } from "../../config/apiConfig";
import { ErrorBoundary } from "../../errors/errorBoundary";
import { toast } from "react-toastify";

export class UserApi {
  private getRequestInfo(method: string, requestBody?: object): RequestInit {
    return {
      method: method,
      headers: commonHeader,
      credentials: "include",
      keepalive: true,
      ...{
        body: JSON.stringify(requestBody),
      },
    };
  }

  private getRequestBody(baseBody: object, extraBody?: object): object {
    // ベースとなる共通データと、メソッドごとの追加データをマージ
    return { ...baseBody, ...extraBody };
  }

  async update(updateData: UpdateUser): Promise<Response | null> {
    const updateUser = endpoints.userInfoUpdate;

    try {
      const requestInfo = this.getRequestInfo("POST", updateData);

      const requestBody = this.getRequestBody(requestInfo);

      const response = await fetch(updateUser, requestBody);
      if (response.ok) {
        console.log("Update user info request is succeeded");
        toast.success("保存に成功");
        return response;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }
  async getByEmail(): Promise<Response | null> {
    const getByEmail = endpoints.getUserByEmail;

    try {
      const requestInfo = this.getRequestInfo("GET");

      const requestBody = this.getRequestBody(requestInfo);

      const response = await fetch(getByEmail, requestBody);
      if (response.ok) {
        console.log("get user info by email request is succeeded");
        return response;
      } else {
        const message = ErrorBoundary(response.status);
        if (response.status === 307) {
          toast.info(message);
        } else {
          toast.error(message);
        }
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  async getRoleInOrganization(
    id: string,
    organization_id: string
  ): Promise<Response | null> {
    const getRoleInOrganization = endpoints.getRoleInOrganization(
      organization_id,
      id
    );

    try {
      const requestInfo = this.getRequestInfo("GET");

      const requestBody = this.getRequestBody(requestInfo);

      const response = await fetch(getRoleInOrganization, requestBody);
      if (response.ok) {
        console.log("get role in organization by email request is succeeded");
        return response;
      } else {
        const message = ErrorBoundary(response.status);
        if (response.status === 307) {
          toast.info(message);
        } else {
          toast.error(message);
        }
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
