import React from "react";
import styles from "./index.module.scss";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";

interface TableComponentProps<TData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  updateData: (rowIndex: number, columnId: keyof TData, value: string) => void;
  isEditable: boolean;
  isNonFuncRequirement?: boolean;
}

const TableContent = <TData extends object>({
  data,
  columns,
  updateData,
  isEditable,
}: TableComponentProps<TData>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const isUrl = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  const getColumnWidth = (columnId: string) => {
    switch (columnId) {
      case "fid":
        return "20px";
      case "機能一覧":
        return "250px";
      case "必要機能":
        return "70px";
      case "プラグイン名":
        return "70px";
      case "価格情報":
        return "120px";
      case "会社名":
        return "70px";
      case "サマリー":
        return "400px";
      case "URL":
        return "170px";
      case "どこで":
          return "20px";
      default:
        return "20px";
    }
  };

  return (
    <table className={styles["table"]}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, rowIndex) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {cell.column.id === 'delete' ? (
                  flexRender(cell.column.columnDef.cell, cell.getContext())
                ) : !isEditable && isUrl(cell.getValue() as string) ? (
                  <div
                    className={styles["link"]}
                    onClick={() =>
                      window.open(cell.getValue() as string, "_blank")
                    }
                    style={{ cursor: "pointer", color: "blue" }}
                  >
                    {cell.getValue() as string}
                  </div>
                ) : (
                  <textarea
                    value={cell.getValue() as string | number}
                    onChange={(e) =>
                      updateData(
                        rowIndex,
                        cell.column.id as keyof TData,
                        e.target.value
                      )
                    }
                    readOnly={!isEditable}
                    style={{ width: "100%", resize: "none" }}
                  />
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableContent;
