import React from "react";
import styles from "./index.module.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { User } from "../../interface/user";

interface MemberItem {
  user: User;
  role: string | "owner" | "contributor" | "member";
  isChecked: boolean;
}

interface ModalProps {
  members: MemberItem[];
  setMembers: React.Dispatch<React.SetStateAction<MemberItem[]>>;
}

const InviteInternalUser: React.FC<ModalProps> = ({ members, setMembers }) => {
  const handleRoleChange = (index: number, role: string) => {
    const updatedMembers = [...members];
    updatedMembers[index].role = role ? role : "contributor";
    setMembers(updatedMembers);
  };

  const handleIsCheckedChange = (index: number, isChecked: boolean) => {
    const updateMembers = [...members];
    updateMembers[index].isChecked = isChecked;
    setMembers(updateMembers);
  };

  return (
    <div className={styles.internalMembers}>
      <div className={styles.memberContainer}>
        {members.map((member, index) => (
          <div key={index} className={styles.member}>
            <AccountCircleIcon style={{ fontSize: 40, color: "#1D9FD6" }} />
            <div className={styles.member__info}>
              <div className={styles.member__info__name}>
                <span>{member.user.display_name}</span>
                <p>{member.user.last_name}</p>
                <p>{member.user.first_name}</p>
              </div>
              <div className={styles.member__into__footer}>
                <p>{member.user.email}</p>
              </div>
            </div>
            <div className={styles.roleField}>
              <select
                value={member.role || "contributor"}
                onChange={(e) => handleRoleChange(index, e.target.value)}
                required
              >
                <option value="" disabled>
                  選択
                </option>
                <option value="owner">オーナー</option>
                <option value="contributor">編集者</option>
                <option value="member">閲覧者</option>
              </select>
            </div>
            <div className={styles.member__checkbox}>
              <input
                type="checkbox"
                checked={member.isChecked}
                onChange={(e) => handleIsCheckedChange(index, e.target.checked)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InviteInternalUser;
