import React, { useState } from "react";
import styles from "./index.module.scss";
import close from "../../images/close.svg";
import Button from "../button";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { InviteUser, InviteUserInfo } from "../../interface/organization";
import InviteExternalUser from "../inviteExternalUser";
import { useNavigate } from "react-router-dom";
import { OrganizationApi } from "../../functions/api/organization";
import { toast } from "react-toastify";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InviteOrganizationModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const [members, setMembers] = useState<InviteUserInfo[]>([
    { email: "", role: "" },
  ]);
  const [error, setError] = useState(false);
  const [isLock, setIsLock] = useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const validateForm = () => {
    // メールアドレスまたは権限が未入力のメンバーがいるかをチェック
    return members.every((member) => member.email && member.role);
  };

  const handleSubmit = async () => {
    setIsLock(true);
    if (!validateForm()) {
      setError(true);
      return;
    }
    setError(false);

    const organizationApi = new OrganizationApi();

    const inviteData: InviteUser = {
      invite_users: members,
      organization_id: currentUser.organization_id,
      user_id: currentUser.id,
    };
    console.log(inviteData);

    const response = await organizationApi.invite(inviteData);

    if (response !== null) {
      const data = await response.json();
      console.log("response data: ", data);
      setIsLock(false);
      onClose();
      setTimeout(() => navigate(0), 300); // 状態が反映されるのを待つ
    } else {
      console.error("メンバーの追加に失敗しました");
      toast.error("メンバーの追加に失敗しました");
      setIsLock(false);
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>メンバー招待</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <img src={close} alt="閉じる" />
          </button>
        </div>

        <div className={styles.modalContent}>
          <p className={styles.description}>
            招待されたメンバーに招待メールが届くのでユーザー登録を行なってください。（現在は組織への招待は手動で行なっているため、運営に一報ください。）
          </p>

          <div className={styles.infoSection}>
            <button className={styles.infoButton}>?</button>
            <span className={styles.infoText}>権限について</span>
          </div>

          <InviteExternalUser members={members} setMembers={setMembers} />
          {error && (
            <div className={styles.errorText}>※入力内容に誤りがあります</div>
          )}
        </div>

        <div className={styles.modalFooter}>
          <Button label="キャンセル" styleType="white" onClick={onClose} />
          <Button
            label={!isLock ? "招待" : "招待中"}
            styleType="blue"
            onClick={handleSubmit}
            disabled={isLock}
          />
        </div>
      </div>
    </div>
  );
};

export default InviteOrganizationModal;
