import React from "react";
import styles from "./index.module.scss";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { User } from "../../interface/user";

interface MemberItem {
  user: User;
  role: string | "owner" | "contributor" | "member";
  isChecked: boolean;
}

interface ModalProps {
  members: MemberItem[];
}

const DisplayInvitedMembers: React.FC<ModalProps> = ({ members }) => {
  return (
    <div className={styles.displayContainer}>
      {members.filter((item) => item.isChecked).length > 0 && (
        <div className={styles.displayContainer_text}>
          選択中({members.filter((item) => item.isChecked).length}名)
        </div>
      )}
      <div className={styles.displayContainer_main}>
        {members.map(
          (item) =>
            item.isChecked && (
              <div
                className={styles.displayContainer_main_item}
                key={item.user.id}
              >
                <AccountCircleIcon style={{ fontSize: 24, color: "#1D9FD6" }} />
                <span>{item.user.display_name}</span>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default DisplayInvitedMembers;
