import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { UpdateUser } from "../../interface/user";
import { UserApi } from "../../functions/api/user";

const UserSettings: React.FC = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const passwordResetUrl = process.env.REACT_APP_AZURE_PASSWORD_UPDATE;
  const navigate = useNavigate();

  const handleUpdate = async () => {
    const updateData: UpdateUser = {
      last_name: lastName,
      first_name: firstName,
      display_name: displayName,
      user_id: `${currentUser?.id}`,
      organization_id: `${currentUser?.organization_id}`,
    };

    const userApi = new UserApi();

    const response = await userApi.update(updateData);
    if (response !== null) {
      console.log("User Info Saved:", {
        firstName,
        lastName,
        displayName,
        email,
      });
      navigate("/signin");
    }
  };

  useEffect(() => {
    setFirstName(currentUser.first_name || "");
    setLastName(currentUser.last_name || "");
    setDisplayName(currentUser.display_name || "");
    setEmail(currentUser.email || "");
  }, [currentUser]); // userが変わるたびに実行される

  if (!currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>ユーザー設定</h1>
      <div className={styles["form-group"]}>
        <label>姓</label>
        <input
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className={styles["form-group"]}>
        <label>名</label>
        <input
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className={styles["form-group"]}>
        <label>表示名</label>
        <input
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>
      <div className={styles["form-group"]}>
        <label>メールアドレス</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          readOnly
          style={{ border: "none", outline: "none" }}
        />
      </div>
      <div className={styles["form-group"]}>
        <label>パスワード</label>
        <a href={passwordResetUrl} className={styles["password-link"]}>
          変更
        </a>
      </div>
      <div>
        <button className={styles["save-button"]} onClick={handleUpdate}>
          保存
        </button>
      </div>
    </div>
  );
};

export default UserSettings;
