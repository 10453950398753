import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Organization } from '../../interface/organization';
import { OrganizationApi } from '../../functions/api/organization';
import { setOrganization } from '../../slices/auth';

export const useOrganization = (organizationId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrganization = async () => {
      if (!organizationId) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        const organizationApi = new OrganizationApi();
        const response = await organizationApi.get(organizationId);
        
        if (response !== null) {
          const data: Organization = await response.json();
          dispatch(setOrganization(data));
        } else {
          throw new Error('Failed to fetch organization data');
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : '組織データの取得に失敗しました');
        console.error('Error fetching organization:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganization();
  }, [organizationId, dispatch]);

  return { isLoading, error };
};
