import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { fetchUserInfo, clearAuth, fetchRole } from "../../slices/auth";
import { azureLoginUri } from "../../config/apiConfig";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Signin: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
        const loadingToastId = toast.loading("認証を確認しています...");

        const userInfoResult = await dispatch(fetchUserInfo());
        if (
          userInfoResult.type === "auth/fetchUserInfo/fulfilled" &&
          userInfoResult.payload
        ) {
          const userInfo = userInfoResult.payload;

          const roleResult = await dispatch(fetchRole(userInfo));
          if (roleResult.type === "auth/fetchRole/fulfilled") {
            console.log("認証・ロール取得成功");
            toast.update(loadingToastId, {
              render: "認証に成功しました",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            navigate("/");
          } else {
            console.error("組織ロールの取得に失敗しました");
            dispatch(clearAuth());
            window.location.href = azureLoginUri;
          }
        } else {
          console.error("ユーザー情報の取得に失敗しました");
          dispatch(clearAuth());
          window.location.href = azureLoginUri;
        }
      } catch (error) {
        console.error("致命的なエラー:", error);
        dispatch(clearAuth());
        window.location.href = azureLoginUri;
      }
    };

    handleAuthentication();
  }, [dispatch, navigate]);

  return <></>;
};

export default Signin;
