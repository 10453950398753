import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
} from "@tanstack/react-table";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { RootState } from "../../store";
import { setCurrentProject } from "../../slices/auth";
import { ProjectApi } from "../../functions/api/project";
import { Project, UpdateProject } from "../../interface/project";

import ProjectModalContent from "../projectModalContent";
import InviteProjectModal from "../inviteProjectModal";
import FormField from "../formField";
import Modal from "../modal";
import Button from "../button";
import styles from "./index.module.scss";

interface ListComponentProps<LData> {
  data: LData[];
  columns: ColumnDef<LData>[];
  updateData: (rowIndex: number, columnId: keyof LData, value: string) => void;
  tabTitle: string;
}

interface FormFieldConfig {
  label: string;
  name: string;
  type?: "text" | "textarea" | "date";
  required?: boolean;
  placeholder?: string;
  value: string;
}

const ProjectTopListContent = <TData extends object>({
  data,
  columns,
  updateData,
  tabTitle,
}: ListComponentProps<TData>) => {
  const { currentUser, currentProject } = useSelector(
    (state: RootState) => state.auth
  );

  const [projectData, setProjectData] = useState<UpdateProject>({
    name: currentProject?.name || null,
    description: currentProject?.description || null,
    start_date: currentProject?.start_date
      ? new Date(currentProject.start_date)
      : null,
    end_date: currentProject?.end_date
      ? new Date(currentProject.end_date)
      : null,
    client_name: currentProject?.client?.name || null,
    client_url: currentProject?.client?.url || null,
    project_id: currentProject.id || "",
    organization_id: currentUser?.organization_id || "",
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLock, setIsLock] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const startDateString =
    projectData.start_date instanceof Date
      ? projectData.start_date.toISOString().split("T")[0]
      : "";
  const endDateString =
    projectData.end_date instanceof Date
      ? projectData.end_date.toISOString().split("T")[0]
      : "";

  useEffect(() => {
    // 必須項目がすべて入力されているかをチェックし、boolean 型に変換
    const isFormFilled = Boolean(
      projectData.name && projectData.start_date && projectData.end_date
    );
    setIsFormValid(isFormFilled);
  }, [projectData]);
  // モーダルの開閉を管理するステート

  // 編集ボタンのクリックでモーダルを開く
  const openModal = () => setModalOpen(true);

  // モーダルを閉じる関数
  const closeModal = () => setModalOpen(false);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Check if the field is a date field and convert accordingly
    if (name === "start_date" || name === "end_date") {
      setProjectData((prevData) => ({
        ...prevData,
        [name]: value ? new Date(value) : null, // Convert string to Date
      }));
    } else {
      setProjectData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // プロジェクトデータの更新処理
  const handleUpdateProject = async () => {
    setIsLock(true);
    if (
      currentProject.id === null ||
      currentUser?.organization_id === undefined
    ) {
      toast.error("不正な入力形式です");
      return;
    }
    const preparedProjectData: UpdateProject = {
      ...projectData,
      project_id: currentProject.id,
      organization_id: currentUser?.organization_id,
    };

    const projectApi = new ProjectApi();

    const response = await projectApi.update(preparedProjectData);

    if (response !== null) {
      const data: Project = await response.json();
      dispatch(setCurrentProject(data));

      navigate("/project-top");

      setTimeout(() => navigate(0), 300); // 状態が反映されるのを待つ

      closeModal(); // モーダルを閉じる
    }
    setIsLock(false);
  };

  // タブに応じたモーダルコンテンツ
  const getModalContent = () => {
    switch (tabTitle) {
      case "詳細情報":
        return (
          <ProjectModalContent
            projectData={projectData}
            handleInputChange={handleInputChange}
            handleInputDescriptionChange={() => {}}
            handleUpdateProject={handleUpdateProject}
            closeModal={closeModal}
            isFormValid={isFormValid}
            isLock={isLock}
            startDateString={startDateString}
            endDateString={endDateString}
            buttonLabel={!isLock ? "保存" : "保存中"}
            isEditMode={true}
          />
        );
      case "メンバー":
        return (
          <InviteProjectModal
            isAdmin={false}
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
        );
      default:
        return <div>デフォルトの編集コンテンツ</div>;
    }
  };

  // 特定のタブ（メンバー）かどうかを判定
  const isMemberTab = tabTitle === "メンバー";

  return (
    <div className={styles.ProjectTopListContent}>
      {tabTitle !== "ダッシュボード" && (
        <div className={styles.title}>
          <h3>{tabTitle}</h3>
          {tabTitle === "詳細情報" ? (
            <Button label="編集" styleType="blue" onClick={openModal} />
          ) : tabTitle === "メンバー" ? (
            <Button label="メンバー追加" styleType="blue" onClick={openModal} />
          ) : null}
        </div>
      )}

      {isMemberTab ? (
        <ul className={styles.memberList}>
          {list
            .getRowModel()
            .rows.sort((a, b) => {
              // Define role priorities
              const rolePriority: Record<string, number> = {
                オーナー: 1, // Owner
                編集者: 2, // Editor
                閲覧者: 3, // Viewer
              };

              const roleA =
                a
                  .getVisibleCells()
                  .find((cell) => cell.column.id === "role")
                  ?.getValue() || "";
              const roleB =
                b
                  .getVisibleCells()
                  .find((cell) => cell.column.id === "role")
                  ?.getValue() || "";

              // Use priorities for sorting
              return (
                (rolePriority[roleA as string] || 99) -
                (rolePriority[roleB as string] || 99)
              );
            })
            .map((row) => (
              <li className={styles.memberItem} key={row.id}>
                <div className={styles.memberAvatar}>
                  <AccountCircleIcon
                    style={{ fontSize: 40, color: "#1D9FD6" }}
                  />
                </div>
                <div className={styles.memberDetails}>
                  {row.getVisibleCells().map((cell) => (
                    <span
                      key={cell.id}
                      className={
                        cell.column.id === "role"
                          ? `${styles.memberRole} ${
                              cell.getValue() === "オーナー"
                                ? styles.ownerRole
                                : cell.getValue() === "編集者"
                                ? styles.editorRole
                                : styles.viewerRole
                            }`
                          : styles.memberInfo
                      }
                    >
                      {cell.column.id === "role" ? (
                        <span>{cell.getValue() as string | number}</span>
                      ) : (
                        (cell.getValue() as string)
                      )}
                    </span>
                  ))}
                </div>
                <div className={styles.moreOptions}>
                  <button>・・・</button>
                </div>
              </li>
            ))}
        </ul>
      ) : (
        <ul className={styles.ul}>
          {list.getRowModel().rows.map((row, rowIndex) => (
            <li className={styles.li} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <div key={cell.id}>
                  {tabTitle === "詳細情報" ? (
                    <span>{cell.getValue() as string}</span>
                  ) : (
                    <input
                      value={cell.getValue() as string | number}
                      onChange={(e) =>
                        updateData(
                          rowIndex,
                          cell.column.id as keyof TData,
                          e.target.value
                        )
                      }
                      style={{ width: "100%" }}
                    />
                  )}
                </div>
              ))}
            </li>
          ))}
        </ul>
      )}

      <Modal
        label={`${tabTitle}の編集`}
        isOpen={isModalOpen}
        onClose={closeModal}
        content={getModalContent()}
      />
    </div>
  );
};

export default ProjectTopListContent;
