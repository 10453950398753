import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import styles from "./index.module.scss";
import Button from "../button";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { setCurrentProjectRequirements } from "../../slices/auth";
import ReactMarkdown from "react-markdown";
import ExportModal, { DocumentExportData } from "../exportModal";
import { useNavigationPrompt } from "../../functions/navigationHooks";
import { ReactComponent as DeleteIcon } from "../../images/delete_row.svg";
import { RequirementsApi } from "../../functions/api/requirements";
import { DocumentApi } from "../../functions/api/document";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from "@dnd-kit/sortable";
import DocumentHeader from "../documentHeader";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DragDrop } from "../../images/drag_drop.svg";

// 型定義
interface Table {
  headers: string[];
  rows: string[][];
}

interface ParsedData {
  [key: string]: {
    システム概要?: {
      その他?: string[];
      メリット?: string[];
      主な機能?: Table;
      業務フロー?: string[];
      目的?: string[];
      ユースケーステーブル?: Table;
    };
  };
}

interface FormattedRow {
  id: string;
  [key: string]: string;
}

const SortableItem = ({
  id,
  children,
  isEditable,
}: {
  id: string;
  children: React.ReactNode;
  isEditable: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id });

  const style = { transform: CSS.Transform.toString(transform) };

  return (
    <tr ref={setNodeRef} style={style}>
      {isEditable && (
        <td {...listeners} {...attributes} style={{ cursor: "grab" }}>
          <DragDrop />
        </td>
      )}
      {children}
    </tr>
  );
};

const adjustTextareaHeight = (textarea: HTMLTextAreaElement | null) => {
  if (textarea) {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
};

const EditableTable = forwardRef(
  (
    {
      data,
      columns,
      onChange,
      isEditable,
    }: {
      data: Record<string, string>[]; // 各行データの型
      columns: ColumnDef<Record<string, string>>[]; // 列定義の型
      onChange: (data: Record<string, string>[]) => void;
      isEditable: boolean;
    },
    ref
  ) => {
    const [tableData, setTableData] = useState(data);
    const initialDataRef = useRef<Record<string, string>[]>(data); // 初期データを保存

    useEffect(() => {
      setTableData(JSON.parse(JSON.stringify(data))); // 最新のデータを設定
      initialDataRef.current = JSON.parse(JSON.stringify(data)); // 初期データを更新
    }, [data]);

    useImperativeHandle(ref, () => ({
      resetTableData: () => {
        const resetData = JSON.parse(JSON.stringify(initialDataRef.current)); // 深いコピーを作成
        setTableData(resetData);
        onChange(resetData); // 親コンポーネントにも通知
      },
    }));

    const handleInputChange = (
      rowIndex: number,
      columnId: string,
      value: string
    ) => {
      const updatedData = [...tableData];
      updatedData[rowIndex][columnId] = value;
      setTableData(updatedData);
      onChange(updatedData); // 外部のデータも更新
    };

    const addRow = () => {
      const emptyRow = columns.reduce((acc, col: any) => {
        if (col.accessorKey === "実装方法") {
          acc[col.accessorKey as string] = "標準機能"; // 初期値を設定
        } else {
          acc[col.accessorKey as string] = "";
        }
        return acc;
      }, {} as Record<string, string>);
      const updatedData = [...tableData, emptyRow];
      setTableData(updatedData);
      onChange(updatedData);
    };

    const deleteRow = (rowIndex: number) => {
      const updatedData = tableData.filter((_, index) => index !== rowIndex);
      setTableData(updatedData);
      onChange(updatedData);
    };

    const table = useReactTable({
      data: tableData,
      columns: [
        ...columns,
        {
          accessorKey: "delete",
          header: "",
          cell: ({ row }) =>
            isEditable ? (
              <button
                onClick={() => deleteRow(row.index)}
                style={{ cursor: "pointer", justifyContent: "flex-end" }}
              >
                <DeleteIcon width={16} height={16} />
              </button>
            ) : null,
        },
      ],
      getCoreRowModel: getCoreRowModel(),
    });

    const implementationOptions = [
      "標準機能",
      "プラグイン",
      "カスタマイズ",
      "外部サービス",
    ];

    return (
      <>
        <table className={styles["table"]}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className={styles["table-tr"]} key={headerGroup.id}>
                {isEditable && <th></th>}
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isEditable ? (
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={(event) => {
                  const { active, over } = event;
                  if (active && over && active.id !== over.id) {
                    const oldIndex = table
                      .getRowModel()
                      .rows.findIndex((item) => item.id === active.id);
                    const newIndex = table
                      .getRowModel()
                      .rows.findIndex((item) => item.id === over.id);

                    if (oldIndex !== -1 && newIndex !== -1) {
                      const updatedData = arrayMove(
                        tableData,
                        oldIndex,
                        newIndex
                      );
                      setTableData(updatedData);
                      onChange(updatedData.map(({ id, ...row }) => row));
                    }
                  }
                }}
              >
                <SortableContext
                  items={table.getRowModel().rows.map((item) => item.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {table.getRowModel().rows.map((row, rowIndex) => (
                    <SortableItem
                      key={row.id}
                      id={row.id}
                      isEditable={isEditable}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td className={styles["table-td"]} key={cell.id}>
                          {cell.column.id === "delete" ? (
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          ) : isEditable ? (
                            cell.column.id === "実装方法" ? (
                              <select
                                value={String(cell.getValue() || "")}
                                onChange={(e) =>
                                  handleInputChange(
                                    rowIndex,
                                    cell.column.id,
                                    e.target.value
                                  )
                                }
                              >
                                {implementationOptions.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <textarea
                                value={String(cell.getValue() || "")} // 型を文字列に変換
                                onChange={(e) =>
                                  handleInputChange(
                                    rowIndex,
                                    cell.column.id,
                                    e.target.value
                                  )
                                }
                                onInput={(e) =>
                                  adjustTextareaHeight(
                                    e.target as HTMLTextAreaElement
                                  )
                                }
                                readOnly={!isEditable}
                              />
                            )
                          ) : (
                            <textarea
                              value={cell.getValue() as string} // 型アサーションを利用
                              onChange={(e) =>
                                handleInputChange(
                                  rowIndex,
                                  cell.column.id,
                                  e.target.value
                                )
                              }
                              onInput={(e) =>
                                adjustTextareaHeight(
                                  e.target as HTMLTextAreaElement
                                )
                              }
                              readOnly={!isEditable}
                            />
                          )}
                        </td>
                      ))}
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            ) : (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {cell.column.id === "delete" ? (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : (
                        <textarea
                          value={cell.getValue() as string}
                          readOnly={!isEditable}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
        {isEditable && (
          <Button onClick={addRow} label="+ 行を追加" styleType="blue" />
        )}
      </>
    );
  }
);

// メインコンポーネント
const ContentView: React.FC = () => {
  const [parsedContent, setParsedContent] = useState<any>(null);
  const [initialFuntionsTableData, setInitialFunctionsTableData] = useState<
    Record<string, string>[]
  >([]);
  const [initialUsecaseTableData, setInitialUsecaseTableData] = useState<
    Record<string, string>[]
  >([]);
  const [functionsTable, setFunctionsTable] = useState<
    Record<string, string>[]
  >([]);
  const [usecaseTable, setUsecaseTable] = useState<Record<string, string>[]>(
    []
  );
  const [initialContent, setInitialContent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { currentUser, currentProject } = useSelector(
    (state: RootState) => state.auth
  );
  const textAreaRefs = useRef<HTMLTextAreaElement[]>([]); // 全てのテキストエリアを参照
  const [showExportModal, setShowExportModal] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const navigate = useNavigate();
  const editableTableRef = useRef<{ resetTableData: () => void }>(null); // ref を使用して EditableTable を制御

  useEffect(() => {
    textAreaRefs.current = [];
  }, [parsedContent, isEditable]);

  const toggleEditMode = () => {
    if (isEditable) {
      handleUpdate(); // 保存処理を実行
    } else {
      handleLock();
    }
  };
  const handleLock = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.system_proposal.id) {
      return toast.error("システム概要書が見つかりません");
    }

    const response = await documentApi.lock(
      currentProject.requirements.contents.system_proposal.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("system proposal is locked");
      setIsEditable(true);
    }
  };

  const findMatchingMembers = (data: any) => {
    const cleanData = (obj: any) => {
      for (const key in obj) {
        if (typeof obj[key] === "object" && key === "主な機能") {
          if ("headers" in obj[key] && "rows" in obj[key]) {
            obj[key].rows = obj[key].rows.filter(
              (row: any) =>
                Array.isArray(row) &&
                row.some((value) => value !== null && value !== "")
            );
          }
          cleanData(obj[key]);
        } else if (
          typeof obj[key] === "object" &&
          key === "ユースケーステーブル"
        ) {
          if ("headers" in obj[key] && "rows" in obj[key]) {
            obj[key].rows = obj[key].rows.filter((row: any) => {
              if (Array.isArray(row)) {
                const nonEmptyValues = row.filter(
                  (value) => value !== null && value !== ""
                );
                return nonEmptyValues.length > 1;
              }
              return false;
            });
          }
          cleanData(obj[key]);
        }
      }
    };

    cleanData(data);
    return data;
  };

  const handleUpdate = async () => {
    if (!currentProject.requirements.contents.system_proposal.id) {
      toast.error("システム概要書が見つかりません");
      return;
    }
    // ドキュメントのアップデートAPIリクエスト
    const documentApi = new DocumentApi();

    const { 提案システム } = parsedContent;

    const cleanedData = {
      ...parsedContent,
      提案システム: {
        ...提案システム,
        システム概要: findMatchingMembers(提案システム.システム概要),
      },
    };
    setParsedContent(cleanedData);
    const response = await documentApi.update(
      currentProject.requirements.contents.system_proposal.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id,
      cleanedData
    );

    if (response !== null) {
      console.log("system proposal is unlocked");
      const formatTableData = FormattedTableData(cleanedData);
      setUsecaseTable(formatTableData[0]);
      setFunctionsTable(formatTableData[1]);
      setIsEditable(false);
    }
  };

  const handleUnlock = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.system_proposal.id) {
      toast.error("システム概要書が見つかりません");
      return;
    }

    const response = await documentApi.unlock(
      currentProject.requirements.contents.system_proposal.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("system proposal is unlocked");
    }
  };
  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    "編集内容が保存されていません。ページを離れてもよろしいですか？",
    handleUpdate,
    handleUnlock
  );

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (!currentProject.requirements.contents.system_proposal.id) {
          toast.error("システム構成図が見つかりません");
          return;
        }

        const documentApi = new DocumentApi();

        // ロック解除処理
        documentApi.unlock(
          currentProject.requirements.contents.system_proposal.id,
          currentUser?.id,
          currentProject.id,
          currentUser?.organization_id
        );

        // カスタムメッセージ（古いブラウザでは表示される）
        event.preventDefault();
        event.returnValue = ""; // Chrome用: 空文字を指定
      }
    };

    // beforeunload イベントの登録
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // クリーンアップ
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditable, currentUser, currentProject]);

  const formatTableData = (
    headers: string[],
    rows: string[][]
  ): FormattedRow[] => {
    return rows.map((row: string[], rowIndex: number) =>
      row.reduce(
        (acc, value, index) => {
          acc[headers[index]] = value;
          return acc;
        },
        { id: `row-${rowIndex}` } as FormattedRow
      )
    );
  };

  const FormattedTableData = (parsedData: ParsedData) => {
    const firstKey = Object.keys(parsedData)[0];
    const systemOverview = parsedData[firstKey]?.["システム概要"];

    // Extract data for "ユースケーステーブル"
    const usecaseHeaders =
      systemOverview?.["ユースケーステーブル"]?.headers || [];
    const usecaseRows = systemOverview?.["ユースケーステーブル"]?.rows || [];
    const formattedUsecaseData = formatTableData(usecaseHeaders, usecaseRows);

    // Extract data for "主な機能"
    const functionsHeaders = systemOverview?.["主な機能"]?.headers || [];
    const functionsRows = systemOverview?.["主な機能"]?.rows || [];
    const formattedFunctionsData = formatTableData(
      functionsHeaders,
      functionsRows
    );

    return [formattedUsecaseData, formattedFunctionsData];
  };

  const fetchProjectsData = async () => {
    if (!currentProject.requirements.contents.system_proposal.id) {
      toast.error("システム概要書が見つかりません");
      setIsLoading(false);
      return navigate("/project-top");
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.get(
      currentProject.requirements.contents.system_proposal.id,
      currentProject.id
    );
    if (response !== null) {
      setInitialContent(response);
      if (response.content) {
        try {
          const parsedData: ParsedData = JSON.parse(response.content);
          setParsedContent(parsedData);

          // ユースケーステーブルの初期データを作成し、保存
          const formatTableData = FormattedTableData(parsedData);
          setUsecaseTable(formatTableData[0]);
          setInitialUsecaseTableData(formatTableData[0]);

          // 主な機能テーブルの初期データを作成し、保存
          setFunctionsTable(formatTableData[1]);
          setInitialFunctionsTableData(formatTableData[1]);
        } catch (error) {
          console.error("contentのデコードエラー:", error);
          setParsedContent(null);
        }
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      navigate("/project-top");
    }
  };

  useEffect(() => {
    fetchProjectsData();
  }, []);

  const denormalizeTableData = (
    tableData: Record<string, string>[],
    headers: string[]
  ) => {
    return tableData.map((row) => {
      return headers.map((header) => row[header] || "");
    });
  };

  const handleUsecaseTableChange = (updatedTable: any[]) => {
    if (parsedContent) {
      const firstKey = Object.keys(parsedContent)[0];
      const updatedParsedContent = { ...parsedContent };

      if (updatedParsedContent[firstKey]?.["システム概要"]) {
        const headers =
          updatedParsedContent[firstKey]["システム概要"]["ユースケーステーブル"]
            .headers;
        updatedParsedContent[firstKey]["システム概要"][
          "ユースケーステーブル"
        ].rows = denormalizeTableData(updatedTable, headers);
        setParsedContent(updatedParsedContent);
      } else {
        console.warn("システム概要またはユースケーステーブルが見つかりません");
      }
    }
  };

  const handleFunctionsTableChange = (updatedTable: any[]) => {
    if (parsedContent) {
      const firstKey = Object.keys(parsedContent)[0];
      const updatedParsedContent = { ...parsedContent };

      if (updatedParsedContent[firstKey]?.["システム概要"]) {
        const headers =
          updatedParsedContent[firstKey]["システム概要"]["主な機能"].headers;
        updatedParsedContent[firstKey]["システム概要"]["主な機能"].rows =
          denormalizeTableData(updatedTable, headers);
        setParsedContent(updatedParsedContent);
      } else {
        console.warn("システム概要または主な機能テーブルが見つかりません");
      }
    }
  };

  if (!parsedContent) return <div>データがありません</div>;

  const firstKey = Object.keys(parsedContent)[0];

  const usecaseColumns: ColumnDef<Record<string, string>>[] = [
    { accessorKey: "ユースケース", header: "ユースケース" },
    { accessorKey: "ユースケース群", header: "ユースケース群" },
    { accessorKey: "アクター", header: "アクター" },
    { accessorKey: "使用サービス", header: "使用サービス" },
    { accessorKey: "実装方法", header: "実装方法" },
  ];

  const functionsColumns: ColumnDef<Record<string, string>>[] = [
    { accessorKey: "機能", header: "機能" },
    { accessorKey: "概要", header: "概要" },
    // { accessorKey: "記載有無", header: "議事録での記載" },
  ];

  const handleNextGenerate = async () => {
    setIsLoading(true);
    const requirementsApi: RequirementsApi = new RequirementsApi();

    const response = await requirementsApi.generateRequirements(
      currentUser.id,
      currentProject.id,
      currentUser.organization_id
    );
    if (response !== null) {
      dispatch(setCurrentProjectRequirements(response));
    }
    setIsLoading(false);
  };

  const renderMarkdownField = (
    field: string,
    value: string[] | Record<string, string[]>
  ) => {
    const handleArrayChange = (index: number, newValue: string) => {
      if (parsedContent && Array.isArray(value)) {
        const updatedContent = { ...parsedContent };
        updatedContent[firstKey]["システム概要"][field][index] = newValue;
        setParsedContent(updatedContent);
      }
    };

    const deleteArrayItem = (index: number) => {
      if (parsedContent && Array.isArray(value)) {
        const updatedContent = { ...parsedContent };
        updatedContent[firstKey]["システム概要"][field].splice(index, 1);
        setParsedContent(updatedContent);
      }
    };

    const addNewArrayItem = () => {
      if (parsedContent && Array.isArray(value)) {
        const updatedContent = { ...parsedContent };
        updatedContent[firstKey]["システム概要"][field].push("");
        setParsedContent(updatedContent);
      }
    };

    const handleTextAreaRef = (
      el: HTMLTextAreaElement | null,
      index: number
    ) => {
      if (el) {
        textAreaRefs.current[index] = el;
        adjustTextareaHeight(el);
      }
    };

    if (Array.isArray(value)) {
      return (
        <div className={styles["markdown-container"]}>
          {!isEditable ? (
            <ReactMarkdown>
              {value.map((item) => `- ${item}`).join("\n")}
            </ReactMarkdown>
          ) : (
            <>
              {value.map((item, index) => (
                <div key={index} className={styles["textarea-wrapper"]}>
                  <textarea
                    className={styles["textarea"]}
                    value={item}
                    onChange={(e) => handleArrayChange(index, e.target.value)}
                    rows={1}
                    ref={(el) => handleTextAreaRef(el, index)}
                  />
                  <button onClick={() => deleteArrayItem(index)}>
                    <DeleteIcon />
                  </button>
                </div>
              ))}
              <Button
                onClick={addNewArrayItem}
                label="+ 項目を追加"
                styleType="blue"
              />
            </>
          )}
        </div>
      );
    } else {
      return <div>不正なデータ形式です。</div>;
    }
  };

  const handleExportClick = () => {
    if (parsedContent) {
      setShowExportModal(true);
    } else {
      console.error("parsedContent is empty");
    }
  };

  const handleCancel = async () => {
    if (!currentProject.requirements.contents.system_proposal.id) {
      toast.error("システム概要書が見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(
      currentProject.requirements.contents.system_proposal.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("unlock system proposal");
      setIsEditable(false);

      if (editableTableRef.current) {
        editableTableRef.current.resetTableData(); // EditableTable のリセットを呼び出し
      }
      setParsedContent(JSON.parse(initialContent.content));
    }
  };

  return (
    <>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{
            type: "document",
            exportData: {
              content: JSON.stringify(parsedContent),
            } as DocumentExportData, // ここでラップ
            name: "system-proposal",
          }}
        />
      )}
      <DocumentHeader document={initialContent} />
      <div className={styles.header__buttons}>
        <Button
          label={isLoading ? "生成中..." : "要件定義書を生成"}
          styleType="blue"
          onClick={handleNextGenerate}
          disabled={isLoading || isEditable}
        />
        {isEditable && (
          <Button
            onClick={handleCancel}
            styleType="white"
            label={"キャンセル"}
          />
        )}
        <Button
          onClick={toggleEditMode}
          styleType="blue"
          label={isEditable ? "保存" : "編集"}
        />
        <Button
          label="エクスポート"
          onClick={handleExportClick}
          styleType="blue"
          disabled={isEditable}
        />
      </div>
      <div className={styles["wrapper"]}>
        <div className={styles["innerDiv"]}>
          <h1 className={styles["system-overview-title"]}>
            {firstKey || "データがありません"}
          </h1>

          <h2>システム概要</h2>

          <h3 className={styles["title"]}>システム導入の目的</h3>

          {renderMarkdownField(
            "目的",
            (parsedContent[firstKey]?.["システム概要"]?.["目的"] as Record<
              string,
              string[]
            >) || ""
          )}

          <h3 className={styles["title"]}>主な機能</h3>
          <EditableTable
            ref={editableTableRef}
            data={functionsTable}
            columns={functionsColumns}
            onChange={handleFunctionsTableChange}
            isEditable={isEditable}
          />

          <h3 className={styles["title"]}>業務フロー</h3>
          {renderMarkdownField(
            "業務フロー",
            (parsedContent[firstKey]?.["システム概要"]?.[
              "業務フロー"
            ] as Record<string, string[]>) || ""
          )}

          <h3 className={styles["title"]}>メリット</h3>
          {renderMarkdownField(
            "メリット",
            (parsedContent[firstKey]?.["システム概要"]?.["メリット"] as Record<
              string,
              string[]
            >) || ""
          )}

          <h2>ユースケーステーブル</h2>
          <EditableTable
            ref={editableTableRef}
            data={usecaseTable}
            columns={usecaseColumns}
            onChange={handleUsecaseTableChange}
            isEditable={isEditable}
          />
        </div>
      </div>
    </>
  );
};

export default ContentView;
