import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Button from "../../components/button";
import TableContent from "../../components/tableContent";
import { Dictionary } from "../../interface/project";
import { ReactComponent as DeleteIcon } from "../../images/delete_row.svg";
import { ProjectApi } from "../../functions/api/project";
import { ColumnDef } from "@tanstack/react-table";
import { toast } from "react-toastify";
import { setCurrentProjectDictionary } from "../../slices/auth";

function createRowData(word: string, description: string): Dictionary {
  const rowData: Dictionary = { word, description };
  return rowData;
}

const Vocabulary = () => {
  const [words, setWords] = useState<Dictionary[]>([]);
  const [previousWords, setPreviousWords] = useState<Dictionary[]>([]);
  const { currentUser, currentProject } = useSelector(
    (state: RootState) => state.auth
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const dispatch = useDispatch();
  const projectApi = new ProjectApi();

  const columns: ColumnDef<(typeof words)[0]>[] = [
    { accessorKey: "word", header: "単語" },
    { accessorKey: "description", header: "説明" },
    {
      accessorKey: "delete",
      header: "",
      cell: ({ row }) =>
        isEditable ? (
          <button
            onClick={() => deleteRow(row.index)}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <DeleteIcon width={16} height={16} />
          </button>
        ) : null,
    },
  ];

  const addNewRow = () => {
    const newRow: Dictionary = createRowData("", "");
    setWords((prevWords) => {
      if (prevWords.length === 0 || prevWords[prevWords.length - 1].word) {
        return [...prevWords, newRow];
      }
      return prevWords;
    });
  };

  const updateData = (
    rowIndex: number,
    columnId: keyof (typeof words)[0],
    value: string
  ) => {
    const updatedData = words.map((row, index) =>
      index === rowIndex ? { ...row, [columnId]: value } : row
    );
    setWords(updatedData);
  };

  const deleteRow = (rowIndex: number) => {
    const updatedData = words.filter((_, index) => index !== rowIndex);
    setWords(updatedData);
  };

  const handleCancel = async () => {
    setIsEditable(false);
    setWords(previousWords);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleSave = async () => {
    if (words.length === 0) {
      setWords([]);
    } else if (!words[words.length - 1].word) {
      setWords(words.slice(0, words.length - 1));
      return;
    }

    const response = await projectApi.upsertVocabulary({
      dictionary: words,
      id: currentProject.id,
      organization_id: currentUser?.organization_id,
    });
    console.log(response);
    if (response !== null) {
      toast.success("保存ボタンが押されました");
      setPreviousWords(words);
      setIsEditable(false);
      dispatch(setCurrentProjectDictionary(words));
    }
  };

  useEffect(() => {
    setWords(currentProject.dictionary);
    setPreviousWords(currentProject.dictionary);
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <div>ロード中・・・</div>;
  }

  return (
    <div>
      <h1>単語登録</h1>
      <div className={styles.buttons}>
        {isEditable && (
          <Button
            onClick={handleCancel}
            styleType="white"
            label={"キャンセル"}
          />
        )}
        {isEditable ? (
          <Button
            onClick={handleSave}
            styleType="blue-generation-each-document"
            label="保存"
          />
        ) : (
          <Button
            onClick={handleEdit}
            styleType="blue-generation-each-document"
            label="編集"
          />
        )}
      </div>
      <TableContent
        data={words}
        columns={columns}
        updateData={(rowIndex, columnId, value) => {
          updateData(rowIndex, columnId, value);
        }}
        isEditable={isEditable}
      />
      {isEditable && (
        <Button label="行を追加" onClick={addNewRow} styleType="blue" />
      )}
    </div>
  );
};

export default Vocabulary;
