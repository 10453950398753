import React, { useEffect, useState } from "react";
import { ChartDisplay } from "../../components/chartDisplay";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DocumentApi } from "../../functions/api/document";
import { toast } from "react-toastify";

const SystemConfigChart: React.FC = () => {
  const [diagramCode, setDiagramCode] = useState<object | null>(null);
  const { currentProject } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true); // エラーが発生したことを設定
  };

  useEffect(() => {
    if (!currentProject?.requirements.contents.system_architecture?.id) {
      toast.error("システム構成図は生成されていません。");
      return navigate("/project-top");
    }

    const fetchSystemArchitecture = async () => {
      if (!currentProject.requirements.contents.system_architecture.id) {
        toast.error("システム構成図が見つかりません");
        return;
      }

      const documentApi = new DocumentApi();

      const response = await documentApi.get(
        currentProject.requirements.contents.system_architecture.id,
        currentProject.id
      );

      if (response !== null) {
        console.log(response);

        const parsedData = JSON.parse(response.content);
        setDiagramCode(parsedData);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error("システム構成図が見つかりません");
        navigate("/project-top");
      }
    };
    fetchSystemArchitecture();
  }, []);

  if (isLoading) {
    return <div>ロード中...</div>;
  }
  return (
    <div>
      <h1>システム構成図</h1>
      {!hasError && diagramCode ? (
        <ChartDisplay
          id={currentProject.requirements.contents.system_architecture.id || ""}
          src={diagramCode}
          onError={handleError}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SystemConfigChart;
