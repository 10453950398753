import React, { useState, useEffect } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import TableContent from "../../components/tableContent";
import { TableExportData } from "../../components/exportModal";
import ExportModal from "../../components/exportModal";
import Button from "../../components/button";
import { useNavigationPrompt } from "../../functions/navigationHooks";
import { ReactComponent as DeleteIcon } from "../../images/delete_row.svg";
import { DocumentApi } from "../../functions/api/document";
import { Document } from "../../interface/document";
import { toast } from "react-toastify";

// 初期データ
interface RowData {
  その他機能: string;
  内訳: string;
  内容: string;
  注記: string;
}

function createRowData(
  その他機能: string,
  内訳: string,
  内容: string,
  注記: string
): RowData {
  const rowData: RowData = { その他機能, 内訳, 内容, 注記 };

  return rowData;
}

const NonFuncRequirement = () => {
  const [data, setData] = useState<RowData[]>([]);
  const [initialData, setInitialData] = useState<RowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showExportModal, setShowExportModal] = useState(false);
  const { currentProject, currentUser } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const [isEditable, setIsEditable] = useState(false);

  const toggleEditMode = () => {
    if (isEditable) {
      handleUpdate(); // 保存処理を実行
    } else {
      handleLock();
    }
  };

  const handleLock = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.non_functional_requirement.id) {
      toast.error("非機能要件書が見つかりません");
      return;
    }

    const response = await documentApi.lock(
      currentProject.requirements.contents.non_functional_requirement.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("non functional requirement is locked");
      setIsEditable(true);
    }
  };
  const handleUpdate = async () => {
    if (!currentProject.requirements.contents.non_functional_requirement.id) {
      toast.error("非機能要件書が見つかりません");
      return;
    }

    const headers = ["その他機能", "内訳", "内容", "注記"];
    const rows = data.map((row) =>
      headers.map((header) => row[header as keyof RowData] || "")
    );

    const updateData = {
      headers,
      rows,
    };

    // ドキュメントのアップデートAPIリクエスト
    const documentApi = new DocumentApi();
    const response = await documentApi.update(
      currentProject.requirements.contents.non_functional_requirement.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id,
      updateData
    );

    if (response !== null) {
      console.log("non functional requirement is unlocked");
      setIsEditable(false);
      setInitialData(data);
    }
  };
  const handleUnlock = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.non_functional_requirement.id) {
      toast.error("非機能要件書が見つかりません");
      return;
    }

    const response = await documentApi.unlock(
      currentProject.requirements.contents.non_functional_requirement.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("non functional requirement is unlocked");
    }
  };

  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    "編集内容が保存されていません。ページを離れてもよろしいですか？",
    handleUpdate,
    handleUnlock
  );

  // データ更新の関数
  const updateData = (
    rowIndex: number,
    columnId: keyof (typeof data)[0],
    value: string
  ) => {
    const updatedData = [...data];
    // フィールドの型に応じて適切に処理
    updatedData[rowIndex][columnId] = value;
    setData(updatedData);
  };

  const getExportData = (): TableExportData => {
    const name = "非機能要件書";
    const headers = ["その他機能", "内訳", "内容", "注記"];
    const rows = data.map((row) =>
      headers.map((header) => row[header as keyof RowData]?.toString() || "")
    );
    return { headers, rows, name };
  };

  useEffect(() => {
    if (
      currentProject?.requirements.contents.non_functional_requirement?.id ==
      null
    ) {
      toast.error("非機能要件書は生成されていません。");
      return navigate("/project-top");
    }

    const fetchNonFunctionalRequirement = async () => {
      if (!currentProject.requirements.contents.non_functional_requirement.id) {
        toast.error("非機能要件書が見つかりません");
        return;
      }

      const documentApi = new DocumentApi();

      const response = await documentApi.get(
        currentProject.requirements.contents.non_functional_requirement.id,
        currentProject.id
      );

      if (response !== null) {
        const rawNonFunctionalRequirement = response.content;
        const parsedNonFunctionalRequirement: any = JSON.parse(
          rawNonFunctionalRequirement
        );
        console.log(parsedNonFunctionalRequirement);
        const nonFunctionalRequirementRows: [] =
          parsedNonFunctionalRequirement.rows;

        let rowsData: RowData[] = [];
        nonFunctionalRequirementRows.forEach((row) => {
          const rowData = createRowData(row[0], row[1], row[2], row[3]);
          rowsData.push(rowData);
        });

        setData(rowsData);
        setInitialData(JSON.parse(JSON.stringify(rowsData)));
        setIsLoading(false);
      } else {
        setIsLoading(false); // ローディング終了
        navigate("/project-top");
      }
    };

    fetchNonFunctionalRequirement();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (
          !currentProject.requirements.contents.non_functional_requirement.id
        ) {
          toast.error("非機能要件書が見つかりません");
          return;
        }

        const documentApi = new DocumentApi();

        // ロック解除処理
        documentApi.unlock(
          currentProject.requirements.contents.non_functional_requirement.id,
          currentUser?.id,
          currentProject.id,
          currentUser?.organization_id
        );

        // カスタムメッセージ（古いブラウザでは表示される）
        event.preventDefault();
        event.returnValue = ""; // Chrome用: 空文字を指定
      }
    };

    // beforeunload イベントの登録
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // クリーンアップ
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditable, currentUser, currentProject]);

  if (isLoading) {
    return <div>ロード中...</div>;
  }

  const handleExportClick = () => {
    setShowExportModal(true);
  };

  const columns: ColumnDef<(typeof data)[0]>[] = [
    { accessorKey: "その他機能", header: "その他機能" },
    { accessorKey: "内訳", header: "内訳" },
    { accessorKey: "内容", header: "内容" },
    { accessorKey: "注記", header: "注記" },
    {
      accessorKey: "delete",
      header: "",
      cell: ({ row }) =>
        isEditable ? (
          <button
            onClick={() => deleteRow(row.index)}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <DeleteIcon width={16} height={16} />
          </button>
        ) : null,
    },
  ];

  const addNewRow = () => {
    const newRow: RowData = createRowData("", "", "", "");
    setData([...data, newRow]);
  };

  const deleteRow = (rowIndex: number) => {
    const updatedData = data.filter((_, index) => index !== rowIndex);
    setData(updatedData); // 状態を更新
  };

  const handleCancel = async () => {
    if (!currentProject.requirements.contents.non_functional_requirement.id) {
      toast.error("非機能要件書が見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(
      currentProject.requirements.contents.non_functional_requirement.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("unlock non functional requirement");
      setData(initialData);
      setIsEditable(false);
    }
  };

  return (
    <div>
      <h1>非機能要件</h1>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{
            type: "table",
            exportData: getExportData(),
            name: "non-functional-requirement",
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        <Button
          label="エクスポート"
          onClick={handleExportClick}
          styleType="blue-generation-each-document"
          disabled={isEditable}
        />
        {isEditable && (
          <Button
            onClick={handleCancel}
            styleType="white"
            label={"キャンセル"}
          />
        )}
        <Button
          onClick={toggleEditMode}
          label={isEditable ? "保存" : "編集"}
          styleType="blue"
        />
      </div>
      <TableContent
        data={data}
        columns={columns}
        updateData={updateData}
        isEditable={isEditable}
      />
      {isEditable && (
        <Button label="行を追加" onClick={addNewRow} styleType="blue" />
      )}
    </div>
  );
};

export default NonFuncRequirement;
