import React from 'react';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import styles from "./index.module.scss";
import { OrganizationMember } from "../../interface/organization";

interface MemberListProps {
  members: OrganizationMember[];
}

const getRolePriority = (role: string): number => {
  const rolePriority: Record<string, number> = {
    admin: 1,
    manager: 2,
    member: 3,
  };
  return rolePriority[role] || 99;
};

const getRoleStyle = (role: string): string => {
  switch (role) {
    case 'admin':
      return styles.adminRole;
    case 'manager':
      return styles.projectAdminRole;
    default:
      return styles.userRole;
  }
};

const getRoleDisplayText = (role: string): string => {
  const roleText: Record<string, string> = {
    admin: '管理者',
    manager: 'プロジェクト管理者',
    member: 'ユーザー'
  };
  return roleText[role] || role;
};

export const MemberList: React.FC<MemberListProps> = ({ members }) => {
  // 型を確認するためのログ
  console.log('Member type check:', members[0]);

  const sortedMembers = [...members].sort((a, b) => {
    return getRolePriority(a.role) - getRolePriority(b.role);
  });

  return (
    <ul className={styles.memberList}>
      {sortedMembers.map((member) => (
        <li className={styles.memberItem} key={member.user.id}>
          <div className={styles.memberAvatar}>
            <AccountCircleIcon style={{ fontSize: 40, color: "#1D9FD6" }} />
          </div>
          <div className={styles.memberDetails}>
            <span className={styles.memberInfo}>{member.user.display_name}</span>
            <span className={styles.memberInfo}>{member.user.email}</span>
            <span className={`${styles.memberRole} ${getRoleStyle(member.role)}`}>
              <span>{getRoleDisplayText(member.role)}</span>
            </span>
          </div>
          <div className={styles.moreOptions}>
            <button>・・・</button>
          </div>
        </li>
      ))}
    </ul>
  );
};
