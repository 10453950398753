import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactTable, ColumnDef, getCoreRowModel } from "@tanstack/react-table";

import InviteOrganizationModal from "../inviteOrganizationModal";
import Modal from "../modal";
import Button from "../button";
import { MemberList } from "./MemberList";
import { OrganizationForm } from "./OrganizationForm";
import { RootState } from "../../store";
import { setOrganization } from "../../slices/auth";
import { UpdateOrganization } from "../../interface/organization";
import { OrganizationApi } from "../../functions/api/organization";
import styles from "./index.module.scss";

interface OrganizationData {
  property: string;
  description: string;
}

interface ListComponentProps<TData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  tabTitle: string;
}

const ListContent = <TData,>({
  data,
  columns,
  tabTitle,
}: ListComponentProps<TData>) => {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { role, currentUser, organization } = useSelector(
    (state: RootState) => state.auth
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (Array.isArray(data)) {
      const typedData = data as unknown as OrganizationData[];
      const organizationName = typedData.find(item => item.property === "組織名")?.description || "";
      const organizationUrl = typedData.find(item => item.property === "URL")?.description || "";
      setName(organizationName);
      setUrl(organizationUrl);
    }
  }, [data]);

  useEffect(() => {
    if (tabTitle === "メンバー") {
      console.log("メンバーデータ:", data);
      console.log("テーブルの行:", table.getRowModel().rows);
    }
  }, [data, table]);

  const handleUpdateOrganization = async () => {
    setIsSaving(true);
    const organizationApi = new OrganizationApi();

    const updateData: UpdateOrganization = {
      name,
      url,
      organization_id: currentUser.organization_id,
    };

    try {
      const response = await organizationApi.update(updateData);
      if (response !== null) {
        dispatch(setOrganization({ ...organization, name, url }));
        setModalOpen(false);
      }
    } catch (error) {
      console.error("Failed to update organization:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const renderModalContent = () => {
    switch (tabTitle) {
      case "詳細情報":
        return (
          <OrganizationForm
            name={name}
            url={url}
            isSaving={isSaving}
            onNameChange={setName}
            onUrlChange={setUrl}
            onSave={handleUpdateOrganization}
            onCancel={() => setModalOpen(false)}
          />
        );
      case "メンバー":
        return (
          <InviteOrganizationModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
          />
        );
      default:
        return <div>デフォルトの編集コンテンツ</div>;
    }
  };

  const isMemberTab = tabTitle === "メンバー";

  return (
    <div className={styles.listContent}>
      <div className={styles.title}>
        <h3>{tabTitle}</h3>
        {!isMemberTab && (
          <Button label="編集" styleType="blue" onClick={() => setModalOpen(true)} />
        )}
      </div>

      {isMemberTab ? (
        <MemberList members={organization.members} />
      ) : (
        <ul className={styles.ul}>
          {table.getRowModel().rows.map((row) => (
            <li className={styles.li} key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <div key={cell.id}>
                  <label style={{ width: "100%" }}>
                    {cell.getValue() as string}
                  </label>
                </div>
              ))}
            </li>
          ))}
        </ul>
      )}

      <Modal
        label={`${tabTitle}の編集`}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        content={renderModalContent()}
      />
    </div>
  );
};

export default ListContent;
