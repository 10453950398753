import React, { useEffect, useState } from "react";
import { ChartDisplay } from "../../components/chartDisplay";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DocumentApi } from "../../functions/api/document";
import { toast } from "react-toastify";

const WorkFlow: React.FC = () => {
  const [diagramData, setDiagramData] = useState<object | null>(null);
  const { currentProject } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true); // エラーが発生したことを設定
  };

  // Fetch the ER diagram when component mounts
  useEffect(() => {
    if (currentProject?.requirements.contents.work_flow?.id == null) {
      toast.error("業務フロー図は生成されていません。");
      return navigate("/project-top");
    }

    const fetchERDiagram = async () => {
      if (!currentProject.requirements.contents.work_flow.id) {
        toast.error("業務フロー図が見つかりません");
        setIsLoading(false);
        return navigate("/project-top");
      }

      const documentApi = new DocumentApi();

      const response = await documentApi.get(
        currentProject.requirements.contents.work_flow.id,
        currentProject.id
      );

      if (response !== null) {
        const parsedData = JSON.parse(response.content);
        // ExcalidrawElement配列または`mermaid`プロパティが正しく存在するか確認
        if (parsedData.elements || parsedData.mermaid) {
          setDiagramData(parsedData);
          setIsLoading(false);
        }
      } else {
        toast.error("業務フロー図の取得に失敗しました");
        setIsLoading(false); // ローディング終了
        navigate("/project-top");
      }
    };
    fetchERDiagram();
  }, []);

  if (isLoading) {
    return <div>ロード中...</div>;
  }

  return (
    <div>
      <h1>業務フロー図</h1>
      {!hasError && diagramData ? (
        <ChartDisplay
          id={currentProject.requirements.contents.work_flow?.id || ""}
          src={diagramData}
          onError={handleError}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default WorkFlow;
