import React, { useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DocumentApi } from "../../functions/api/document";
import { toast } from "react-toastify";

interface DeleteDocumentProps {
  onClose: () => void;
  documentId: string;
  onDeleteSuccess: (deletedId: string) => void;
}

const DeleteDocumentModal: React.FC<DeleteDocumentProps> = ({
  onClose,
  documentId,
  onDeleteSuccess,
}) => {
  const { currentUser, currentProject } = useSelector(
    (state: RootState) => state.auth
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);

    const documentAPi = new DocumentApi();

    const response = await documentAPi.delete(
      documentId,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );
    if (response !== null) {
      onDeleteSuccess(documentId);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <div className={styles.modalTitle}>
            <h2>ドキュメントの削除</h2>
            <div className={styles.modalWarning}>
              <span className={styles.warningIcon}>⚠️</span>
              ※この操作は取り消せません
              <div className={styles.description}>
                <p>このドキュメントは生成元になっている可能性があります。</p>
                <p> 一度削除したドキュメントを復元させることはできません。</p>
              </div>
            </div>
            <div className={styles.actions}>
              <button
                className={styles.cancelButton}
                onClick={onClose}
                disabled={isLoading}
              >
                キャンセル
              </button>
              <button
                className={styles.deleteButton}
                onClick={handleDelete}
                disabled={isLoading}
              >
                削除
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteDocumentModal;
