import React, { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthApi } from "../../functions/api/auth";
import styles from "./index.module.scss";

interface UserDropdownProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UserDropdown: React.FC<UserDropdownProps> = ({ isOpen, onClose }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleLogout = async () => {
    const authApi = new AuthApi();

    try {
      const response = await authApi.signout();
      if (response !== null) {
        const data = await response.json();
        const redirectUrl = data.redirectUrl;
        if (redirectUrl) {
          toast.success("ログアウトに成功");
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, 1000);
        }
      }
    } catch (error) {
      toast.error("ログアウトに失敗しました");
      console.error("Logout failed:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.dropdownMenu} ref={dropdownRef}>
      <ul>
        <li>
          <NavLink to="/user-setting">アカウント設定</NavLink>
        </li>
        <li>
          <NavLink to="#" onClick={handleLogout}>
            ログアウト
          </NavLink>
        </li>
      </ul>
    </div>
  );
};
