import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import Button from "../button";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { endpoints } from "../../config/apiConfig";
import ReactMarkdown from "react-markdown";

// テキストエリアの高さを動的に変更する関数
const adjustTextareaHeight = (textarea: HTMLTextAreaElement | null) => {
  if (textarea) {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
};

// 編集可能なテーブルコンポーネント
const EditableTable = ({
  data,
  columns,
  onChange,
  isEditable,
  comparisonData = [],
}: {
  data: any[];
  columns: ColumnDef<any, unknown>[];
  onChange: (data: any[]) => void;
  isEditable: boolean;
  comparisonData?: any[];
}) => {
  const [tableData, setTableData] = useState(data);
  const isDifferent = (rowIndex: number, columnId: string): boolean => {
    if (comparisonData.length > 0) {
      const value1 = tableData[rowIndex]?.[columnId];
      const value2 = comparisonData[rowIndex]?.[columnId];
      if (value1 !== undefined && value2 !== undefined) {
        return value1.localeCompare(value2) !== 0;
      }
    }
    return false;
  };
  const handleInputChange = (
    rowIndex: number,
    columnId: string,
    value: string
  ) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][columnId] = value;
    setTableData(updatedData);
    onChange(updatedData); // 外部のデータも更新
  };

  const addRow = () => {
    const emptyRow = columns.reduce((acc, col: any) => {
      acc[col.accessorKey as string] = "";
      return acc;
    }, {} as Record<string, string>);
    const updatedData = [...tableData, emptyRow];
    setTableData(updatedData);
    onChange(updatedData);
  };

  const deleteRow = (rowIndex: number) => {
    const updatedData = tableData.filter((_, index) => index !== rowIndex);
    setTableData(updatedData);
    onChange(updatedData);
  };

  const table = useReactTable({
    data: tableData,
    columns: [...columns],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <table className={styles["table"]}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={
                    isDifferent(rowIndex, cell.id as string)
                      ? styles["diff-highlight"]
                      : ""
                  }
                >
                  {cell.column.id === "delete" ? (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  ) : (
                    <textarea
                      value={cell.getValue() as string}
                      onChange={(e) =>
                        handleInputChange(
                          rowIndex,
                          cell.column.id,
                          e.target.value
                        )
                      }
                      onInput={(e) =>
                        adjustTextareaHeight(e.target as HTMLTextAreaElement)
                      } // 高さ調整
                      style={{
                        width: "100%",
                        resize: "none",
                      }} // 手動リサイズ無効、スクロールを非表示
                      readOnly={!isEditable}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {isEditable && (
        <Button onClick={addRow} label="+ 行を追加" styleType="blue" />
      )}
    </>
  );
};

interface ModalProps {
  formData: any;
  comparisonData?: any; // 任意の比較用データ
}

// メインコンポーネント
const DocumentView: React.FC<{ formData: any; comparisonData?: any }> = ({
  formData,
  comparisonData,
}) => {
  const [parsedContent, setParsedContent] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, currentProject } = useSelector(
    (state: RootState) => state.auth
  );
  const textAreaRefs = useRef<HTMLTextAreaElement[]>([]); // 全てのテキストエリアを参照
  const [showExportModal, setShowExportModal] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    if (formData?.content) {
      try {
        setParsedContent(JSON.parse(formData.content));
      } catch (error) {
        console.error("contentのデコードエラー:", error);
        setParsedContent(null);
      }
    }
  }, [formData]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        event.preventDefault();
        event.returnValue =
          "編集内容が保存されていません。ページを閉じてもよろしいですか？";

        const unlockDocumentApiUri = endpoints.unlockDocument;

        const unlockDocumentApiRequestBody = {
          id: currentProject.requirements.contents.system_proposal.id,
          user_id: currentUser?.id,
          project_id: currentProject.id,
          organization_id: currentUser?.organization_id,
        };

        navigator.sendBeacon(
          unlockDocumentApiUri,
          new Blob([JSON.stringify(unlockDocumentApiRequestBody)], {
            type: "application/json",
          })
        );
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditable, currentProject, currentUser, currentProject.id]);

  const denormalizeTableData = (tableData: any[], headers: string[]) => {
    return tableData.map((row) => {
      return headers.map((header) => row[header]);
    });
  };

  const handleTableChange = (updatedTable: any[]) => {
    if (parsedContent) {
      const firstKey = Object.keys(parsedContent)[0];
      const updatedParsedContent = { ...parsedContent };

      if (updatedParsedContent[firstKey]?.["システム概要"]) {
        const headers =
          updatedParsedContent[firstKey]["システム概要"]["ユースケーステーブル"]
            .headers;
        updatedParsedContent[firstKey]["システム概要"][
          "ユースケーステーブル"
        ].rows = denormalizeTableData(updatedTable, headers);
        setParsedContent(updatedParsedContent);
      } else {
        console.warn("システム概要またはユースケーステーブルが見つかりません");
      }
    }
  };

  if (!parsedContent) return <div>データがありません</div>;

  const firstKey = Object.keys(parsedContent)[0];

  const systemDetails = parsedContent[firstKey]?.["システム概要"] || {
    目的: ["情報がありません"],
    主な機能: { 機能がありません: [] },
    システム構成: ["情報がありません"],
    メリット: ["情報がありません"],
    ユースケーステーブル: {
      headers: ["項目がありません"],
      rows: [["データがありません"]],
    },
  };

  const functionTableHeaders =
    systemDetails?.["主な機能"]?.headers || [];
  const functionTableRows = systemDetails?.["主な機能"]?.rows || [];

  const functionTableData = functionTableRows.map((row: string[]) => {
    return row.reduce((acc, value, index) => {
      acc[functionTableHeaders[index]] = value;
      return acc;
    }, {} as Record<string, string>);
  });

  const usecaseTableHeaders =
    systemDetails?.["ユースケーステーブル"]?.headers || [];
  const usecaseTableRows = systemDetails?.["ユースケーステーブル"]?.rows || [];

  const usecaseTableData = usecaseTableRows.map((row: string[]) => {
    return row.reduce((acc, value, index) => {
      acc[usecaseTableHeaders[index]] = value;
      return acc;
    }, {} as Record<string, string>);
  });
  const functionColumns: ColumnDef<any>[] = [
    { accessorKey: "機能", header: "機能" },
    { accessorKey: "概要", header: "概要" },
    // { accessorKey: "記載有無", header: "記載有無" },
  ];
  const usecaseColumns: ColumnDef<any>[] = [
    { accessorKey: "ユースケース", header: "ユースケース" },
    { accessorKey: "ユースケース群", header: "ユースケース群" },
    { accessorKey: "アクター", header: "アクター" },
    { accessorKey: "使用サービス", header: "使用サービス" },
    { accessorKey: "実装方法", header: "実装方法" },
  ];

  const renderMarkdownField = (
    field: string,
    value: string[] | Record<string, string[]>,
    comparisonData?: string[] | Record<string, string[]>
  ) => {
    const handleArrayChange = (index: number, newValue: string) => {
      if (parsedContent && Array.isArray(value)) {
        const updatedContent = { ...parsedContent };
        updatedContent[firstKey]["システム概要"][field][index] = newValue;
        setParsedContent(updatedContent);
      }
    };

    if (Array.isArray(value)) {
      return (
        <div className={styles["markdown-container"]}>
          {value.map((item, index) => {
            const comparisonValue = Array.isArray(comparisonData)
              ? comparisonData[index]
              : undefined;
            const highlightClass =
              comparisonValue !== undefined && item !== comparisonValue
                ? styles["diff-highlight"]
                : "";
            console.log("aaa");
            console.log(comparisonValue);
            console.log(item);
            return (
              <div
                key={index}
                className={`${styles["textarea-wrapper"]} ${highlightClass}`}
              >
                {!isEditable ? (
                  <ReactMarkdown>{`- ${item}`}</ReactMarkdown>
                ) : (
                  <textarea
                    className={styles["textarea"]}
                    value={item}
                    onChange={(e) => handleArrayChange(index, e.target.value)}
                    rows={1}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    }

    return <div>不正なデータ形式です。</div>;
  };

  return (
    <>
      <div className={styles["wrapper"]}>
        <div className={styles["innerDiv"]}>
          <h1 className={styles["system-overview-title"]}>
            {firstKey || "データがありません"}
          </h1>

          <h2>システム概要</h2>

          <h3 className={styles["title"]}>システム導入の目的</h3>

          {renderMarkdownField("目的", systemDetails["目的"] as string[])}

          <h3 className={styles["title"]}>主な機能</h3>
          <EditableTable
            data={functionTableData}
            columns={functionColumns}
            onChange={handleTableChange}
            isEditable={isEditable}
          />

          <h3 className={styles["title"]}>業務フロー図</h3>
          {renderMarkdownField(
            "業務フロー",
            systemDetails["業務フロー"] as string[]
          )}

          <h3 className={styles["title"]}>メリット</h3>
          {renderMarkdownField(
            "メリット",
            systemDetails["メリット"] as string[]
          )}

          <h2>ユースケーステーブル</h2>
          <EditableTable
            data={usecaseTableData}
            columns={usecaseColumns}
            onChange={handleTableChange}
            isEditable={isEditable}
          />
        </div>
      </div>
    </>
  );
};

export default DocumentView;
