import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store"; // Reduxの型
import {
  requirementItems,
  designItems,
  developmentItems,
  projectTop as top,
} from "../../config/menuItems";
import { ReactComponent as ArrowDropDownOpen } from "../../images/arrow_drop_down_open.svg";
import { ReactComponent as ArrowDropDownClose } from "../../images/arrow_drop_down_close.svg";
import { ReactComponent as Close } from "../../images/close.svg";
import Button from "../button";

const Sidebar: React.FC = () => {
  // アコーディオンメニューの開閉状態を管理
  const [isRequirementItemsOpen, setIsRequirementItemsOpen] = useState(false);
  const [isDesignItemsMenuOpen, setDesignItemsMenuOpen] = useState(false);
  const [isDevelopmentItemsMenuOpen, setIsDevelopmentItemsMenuOpen] =
    useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isMobileView, setMobileView] = useState(false);

  // アコーディオンを開閉する関数
  const toggleRequirementItemsMenu = () =>
    setIsRequirementItemsOpen(!isRequirementItemsOpen);
  const toggleDesignItemsMenu = () =>
    setDesignItemsMenuOpen(!isDesignItemsMenuOpen);

  const toggleDevelopmentItemsMenu = () =>
    setIsDevelopmentItemsMenuOpen(!isDevelopmentItemsMenuOpen);

  const contentRef = useRef<HTMLUListElement | null>(null);

  const selectedProjectId = useSelector(
    (state: RootState) => state.auth.currentProject?.id || undefined
  );
  const selectedProject = useSelector(
    (state: RootState) => state.auth.currentProject
  );
  const openSideBar = () => {
    setSidebarVisible(true);
  };
  const closeSideBar = () => {
    setSidebarVisible(false);
  };

  const handleFeedbackClick = () => {
    const confirmed = window.confirm(
      "別のページに遷移します。よろしいですか？\nhttps://novelworks.chobiit.me/list_app.html"
    );
    if (confirmed) {
      window.open(
        "https://novelworks.chobiit.me/list_app.html",
        "_blank"
      );
    }
  };

  useEffect(() => {
    return;
  }, [selectedProjectId]);

  useEffect(() => {
    const checkMobileView = () => {
      setMobileView(window.innerWidth <= 768);
    };
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => window.removeEventListener("resize", checkMobileView);
  }, []);

  return (
    <div>
      <div className={styles["hamburger-button"]}>
        <button type="button" onClick={openSideBar}>
          &#9776;
        </button>
      </div>
      <div
        className={`${styles["sidebar"]} ${
          !isMobileView
            ? styles["visible"]
            : isSidebarVisible
            ? styles["visible"]
            : styles["hidden"]
        }`}
      >
        <div className={styles["sidebar_close"]} onClick={closeSideBar}>
          <Close />
        </div>
        <div className={styles["menu"]}>
          {selectedProjectId === undefined ? (
            <div>
              <ul className={styles["menu-wrapper"]}>
                {/* 表示したいメニューアイテム */}
                <li
                  className={`${styles["link"]} ${styles["icon"]} ${styles["icon-project-home"]}`}
                  onClick={closeSideBar}
                >
                  <NavLink to="/">プロジェクト</NavLink>
                </li>
                <li
                  className={`${styles["link"]} ${styles["icon"]} ${styles["icon-organization"]}`}
                  onClick={closeSideBar}
                >
                  <NavLink to="/organization">組織</NavLink>
                </li>
              </ul>
            </div>
          ) : (
            <div className={styles["menu-wrapper"]}>
              <div className={styles["project-name"]}>
                <p>{selectedProject?.name}</p>
              </div>
              <ul
                className={`${styles["accordion-content"]} ${
                  isRequirementItemsOpen ? styles["open"] : ""
                }`}
                ref={contentRef}
                style={{
                  maxHeight: "1000px",
                }}
              >
                {top.map((item, index) => (
                  <li
                    className={`${styles.link} ${styles.icon} ${
                      styles[`icon-${item.icon}`]
                    }`}
                    key={index}
                    onClick={closeSideBar}
                  >
                    <NavLink to={`${item.link}`}>{item.name}</NavLink>
                  </li>
                ))}
              </ul>

              <div
                className={styles.sidebarItemTitle}
                onClick={toggleRequirementItemsMenu}
              >
                <span className={styles["menu-title"]}>要件定義書</span>
                <p className={styles.sidebarArrowDropDown}>
                  {isRequirementItemsOpen ? (
                    <ArrowDropDownClose />
                  ) : (
                    <ArrowDropDownOpen />
                  )}
                </p>
              </div>
              <ul
                className={`${styles["accordion-content"]} ${
                  isRequirementItemsOpen ? styles["open"] : ""
                }`}
                ref={contentRef}
                style={{
                  maxHeight: isRequirementItemsOpen ? "1000px" : "0px",
                }}
              >
                {requirementItems.map((item, index) => (
                  <li
                    className={`${styles.link} ${styles.icon} ${
                      styles[`icon-${item.icon}`]
                    }`}
                    key={index}
                    onClick={closeSideBar}
                  >
                    <NavLink
                      to={`${item.link}`}
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <div
                className={styles.sidebarItemTitle}
                onClick={toggleDesignItemsMenu}
              >
                <span className={styles["menu-title"]}>基本設計書</span>
                <p className={styles.sidebarArrowDropDown}>
                  {isDesignItemsMenuOpen ? (
                    <ArrowDropDownClose />
                  ) : (
                    <ArrowDropDownOpen />
                  )}
                </p>
              </div>
              <ul
                className={`${styles["accordion-content"]} ${
                  isDesignItemsMenuOpen ? styles["open"] : ""
                }`}
                ref={contentRef}
                style={{
                  maxHeight: isDesignItemsMenuOpen ? "1000px" : "0px",
                }}
              >
                {designItems.map((item, index) =>
                  item.name !== "システム構成図" ? (
                    <li
                      className={`${styles.link} ${styles.icon} ${
                        styles[`icon-${item.icon}`]
                      }`}
                      key={index}
                      onClick={closeSideBar}
                    >
                      <NavLink
                        to={`${item.link}`}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ) : (
                    <li
                      className={`${styles.disabled} ${styles.link} ${
                        styles.icon
                      } ${styles[`icon-${item.icon}`]}`}
                      key={index}
                    >
                      {item.name}
                    </li>
                  )
                )}
              </ul>

              <div
                className={styles.sidebarItemTitle}
                onClick={toggleDevelopmentItemsMenu}
              >
                <span className={styles["menu-title"]}>開発</span>
                <p className={styles.sidebarArrowDropDown}>
                  {isDevelopmentItemsMenuOpen ? (
                    <ArrowDropDownClose />
                  ) : (
                    <ArrowDropDownOpen />
                  )}
                </p>
              </div>
              <ul
                className={`${styles["accordion-content"]} ${
                  isDevelopmentItemsMenuOpen ? styles["open"] : ""
                }`}
                ref={contentRef}
                style={{
                  maxHeight: isDevelopmentItemsMenuOpen ? "1000px" : "0px",
                }}
              >
                {developmentItems.map((item, index) => (
                  <li
                    className={`${styles.disabled} ${styles["link"]} ${
                      styles.icon
                    } ${styles[`icon-${item.icon}`]}`}
                    key={index}
                    onClick={closeSideBar}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className={styles["menu-wrapper-feedback"]}>
            <div className={styles["feedback-content"]}>
              <h3>フィードバックを送る</h3>
              <p>
                REQXIGNについてのご意見・ご要望、不具合のご報告などお気軽にお送りください。
              </p>
            </div>
            <Button
              onClick={handleFeedbackClick}
              label="フィードバックを送る"
              styleType="white-feedback"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
