import React, { useState } from "react";
import styles from "./index.module.scss";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkGfm from "remark-gfm";
import {
  Document,
  Packer,
  Paragraph,
  HeadingLevel,
  Table,
  TableRow,
  TableCell,
  WidthType,
} from "docx";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { PluginProposalHeaders } from "../../pages/plugin";
import { toast } from "react-toastify";

export interface TableExportData {
  headers: string[] | typeof PluginProposalHeaders;
  rows: string[][];
  name: string;
}

export interface DocumentExportData {
  content: string;
}

export const exportToExcel = (
  data: TableExportData,
  filename: string,
  name: string
) => {
  if (filename === "") {
    filename = "untitled";
  }
  const workbook = XLSX.utils.book_new();
  if (name === "app-design") {
    // シート1: アプリテーブル
    const appTableData = data.rows.filter((row) => row.length === 3); // アプリテーブルデータ
    const appHeaders = ["アプリID", "アプリ名", "説明"];
    const appSheet = XLSX.utils.json_to_sheet(
      appTableData.map((row) => ({
        [appHeaders[0]]: row[0],
        [appHeaders[1]]: row[1],
        [appHeaders[2]]: row[2],
      }))
    );
    XLSX.utils.book_append_sheet(workbook, appSheet, "アプリテーブル");

    // シート2: アプリ間連携テーブル
    const connectorTableData = data.rows.filter(
      (row) => row.length === 6 && row[0] !== ""
    ); // アプリ間連携テーブルデータ
    const connectorHeaders = [
      "連携ID",
      "ソースアプリID",
      "ターゲットアプリID",
      "関係性",
      "キーフィールド",
      "参照フィールド",
    ];
    const connectorSheet = XLSX.utils.json_to_sheet(
      connectorTableData.map((row) => ({
        [connectorHeaders[0]]: row[0],
        [connectorHeaders[1]]: row[1],
        [connectorHeaders[2]]: row[2],
        [connectorHeaders[3]]: row[3],
        [connectorHeaders[4]]: row[4],
        [connectorHeaders[5]]: row[5],
      }))
    );
    XLSX.utils.book_append_sheet(
      workbook,
      connectorSheet,
      "アプリ間連携テーブル"
    );

    // シート3: 要求機能テーブル
    const requirementTableData = data.rows.filter((row) => row.length === 5); // 要求機能テーブルデータ
    const requirementHeaders = [
      "アプリID",
      "機能ID",
      "名前",
      "説明",
      "実装方法",
    ];
    const requirementSheet = XLSX.utils.json_to_sheet(
      requirementTableData.map((row) => ({
        [requirementHeaders[0]]: row[0],
        [requirementHeaders[1]]: row[1],
        [requirementHeaders[2]]: row[2],
        [requirementHeaders[3]]: row[3],
        [requirementHeaders[4]]: row[4],
      }))
    );
    XLSX.utils.book_append_sheet(
      workbook,
      requirementSheet,
      "要求機能テーブル"
    );
    // シート4: 考慮点
    const considerationPoints = data.rows.filter((row) => row.length === 1); // 考慮点のデータ
    const considerationSheet = XLSX.utils.json_to_sheet(
      considerationPoints.map((point, index) => ({
        考慮点: `${index + 1}`,
        内容: point[0],
      }))
    );
    XLSX.utils.book_append_sheet(workbook, considerationSheet, "考慮点");
  } else if (name === "field-design") {
    // フィールドテーブルシートの作成
    const fieldTableData = data.rows.filter((row) => row.length === 10);
    const fieldTableDataHeaders = [
      "app_id",
      "fid",
      "フィールド名",
      "フィールドコード",
      "フィールドの種類",
      "フィールドタイプ",
      "必須可否",
      "重複可否",
      "選択項目",
      "管理目的",
    ];
    const fieldTableSheet = XLSX.utils.json_to_sheet(
      fieldTableData.map((data) => ({
        [fieldTableDataHeaders[0]]: data[0],
        [fieldTableDataHeaders[1]]: data[1],
        [fieldTableDataHeaders[2]]: data[2],
        [fieldTableDataHeaders[3]]: data[3],
        [fieldTableDataHeaders[4]]: data[4],
        [fieldTableDataHeaders[5]]: data[5],
        [fieldTableDataHeaders[6]]: data[6],
        [fieldTableDataHeaders[7]]: data[7],
        [fieldTableDataHeaders[8]]: data[8],
        [fieldTableDataHeaders[9]]: data[9],
      }))
    );
    XLSX.utils.book_append_sheet(
      workbook,
      fieldTableSheet,
      "フィールドテーブル"
    );

    // アプリ間連携シートの作成
    const connectorTableData = data.rows.filter((row) => row.length === 6);
    const connectorHeaders = [
      "cid",
      "sid",
      "tid",
      "fieldInSid",
      "fieldInTid",
      "relationship",
    ];
    const connectorSheet = XLSX.utils.json_to_sheet(
      connectorTableData.map((row) => ({
        [connectorHeaders[0]]: row[0],
        [connectorHeaders[1]]: row[1],
        [connectorHeaders[2]]: row[2],
        [connectorHeaders[3]]: row[3],
        [connectorHeaders[4]]: row[4],
        [connectorHeaders[5]]: row[5],
      }))
    );
    XLSX.utils.book_append_sheet(
      workbook,
      connectorSheet,
      "アプリ間連携テーブル"
    );
  } else {
    // デフォルト: 単一シート
    const exportData = data.rows.map((row) =>
      row.reduce((acc, cell, index) => {
        acc[data.headers[index]] = cell;
        return acc;
      }, {} as Record<string, string>)
    );

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  }

  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

// オブジェクトか文字列かを判別してパース
const safeParseContent = (content: string) => {
  try {
    return JSON.parse(content);
  } catch {
    return content; // パースできない場合はそのまま返す
  }
};

const parseMarkdownToDocx = (markdownAst: any): (Paragraph | Table)[] => {
  const paragraphs: (Paragraph | Table)[] = [];

  const parseNode = (node: any, listLevel: number = 0) => {
    switch (node.type) {
      case "heading":
        paragraphs.push(
          new Paragraph({
            text: node.children
              .map((child: any) => child.value || "")
              .join(" "),
            heading:
              node.depth === 1
                ? HeadingLevel.HEADING_1
                : node.depth === 2
                ? HeadingLevel.HEADING_2
                : HeadingLevel.HEADING_3,
          })
        );
        break;

      case "paragraph":
        paragraphs.push(
          new Paragraph({
            text: node.children
              .map((child: any) => child.value || "")
              .join(" "),
          })
        );
        break;

      case "list":
        node.children.forEach((item: any) => parseNode(item, listLevel + 1));
        break;

      case "listItem":
        paragraphs.push(
          new Paragraph({
            text: `${"  ".repeat(listLevel)}- ${node.children
              .map((child: any) =>
                child.type === "paragraph"
                  ? child.children.map((c: any) => c.value || "").join(" ")
                  : ""
              )
              .join(" ")}`,
          })
        );
        node.children.forEach((child: any) => {
          if (child.type === "list") parseNode(child, listLevel + 1);
        });
        break;

      case "text":
        paragraphs.push(
          new Paragraph({
            text: node.value || "",
          })
        );
        break;

      case "table":
        const headers = node.children[0]?.children.map(
          (headerNode: any) =>
            new TableCell({
              children: [
                new Paragraph(
                  headerNode.children.map((c: any) => c.value || "").join(" ")
                ),
              ],
            })
        );

        const rows = node.children.slice(1).map(
          (rowNode: any) =>
            new TableRow({
              children: rowNode.children.map(
                (cellNode: any) =>
                  new TableCell({
                    children: [
                      new Paragraph(
                        cellNode.children
                          .map((c: any) => c.value || "")
                          .join(" ")
                      ),
                    ],
                  })
              ),
            })
        );

        if (headers) {
          paragraphs.push(
            new Table({ rows: [new TableRow({ children: headers }), ...rows] })
          );
        }
        break;

      default:
        if (node.children) {
          node.children.forEach((child: any) => parseNode(child, listLevel));
        }
        break;
    }
  };

  parseNode(markdownAst);
  return paragraphs;
};

// Docxファイルとしてエクスポート
export const exportToDocx = async (content: string, filename: string) => {
  try {
    const parsedContent = safeParseContent(content);
    const paragraphs =
      typeof parsedContent === "object"
        ? traverseObjectToDocx(parsedContent)
        : parseMarkdownToDocx(
            unified().use(remarkParse).use(remarkGfm).parse(content)
          );

    const doc = new Document({
      sections: [{ children: paragraphs }],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, `${filename || "untitled"}.docx`);
  } catch (error) {
    console.error("Error exporting DOCX:", error);
  }
};

const parseMarkdownTableToDocx = (
  headers: string[],
  rows: string[][]
): Table => {
  const calculateCellWidth = (text: string): number => {
    const charWidth = 300; // 1文字あたりの幅（DXA単位）
    return text.length * charWidth;
  };

  const columnWidths = headers.map((header) => calculateCellWidth(header));

  const tableRows = [
    new TableRow({
      children: headers.map(
        (header, index) =>
          new TableCell({
            children: [new Paragraph({ text: header })],
            width: { size: columnWidths[index], type: WidthType.DXA },
          })
      ),
    }),
    ...rows.map(
      (row) =>
        new TableRow({
          children: row.map(
            (cell, index) =>
              new TableCell({
                children: [new Paragraph(cell)],
                width: { size: columnWidths[index], type: WidthType.DXA },
              })
          ),
        })
    ),
  ];

  return new Table({
    rows: tableRows,
    width: { size: 10000, type: WidthType.PERCENTAGE }, // テーブル全体幅を指定
    columnWidths: columnWidths, // 各列の幅を指定
  });
};

// Markdownエクスポート
export const exportToMarkdown = (
  data: DocumentExportData,
  filename: string
) => {
  try {
    const parsedContent = safeParseContent(data.content);
    const markdownContent =
      typeof parsedContent === "object"
        ? convertObjectToMarkdown(parsedContent)
        : parsedContent;

    const blob = new Blob([markdownContent], {
      type: "text/markdown;charset=utf-8",
    });
    saveAs(blob, `${filename || "untitled"}.md`);
  } catch (error) {
    console.error("Error exporting Markdown:", error);
  }
};

/// JSONオブジェクトを再帰的にDocx構造に変換
const traverseObjectToDocx = (
  obj: Record<string, any>,
  level: number = 1
): (Paragraph | Table)[] => {
  const paragraphs: (Paragraph | Table)[] = [];
  for (const [key, value] of Object.entries(obj)) {
    paragraphs.push(
      new Paragraph({
        text: key,
        heading: level === 1 ? HeadingLevel.HEADING_1 : HeadingLevel.HEADING_2,
      })
    );

    if (typeof value === "string") {
      paragraphs.push(new Paragraph({ text: value }));
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        if (typeof item === "string") {
          paragraphs.push(new Paragraph({ text: `- ${item}` }));
        } else if (typeof item === "object") {
          paragraphs.push(...traverseObjectToDocx(item, level + 1));
        }
      });
    } else if (typeof value === "object") {
      if (value.headers && value.rows) {
        paragraphs.push(parseMarkdownTableToDocx(value.headers, value.rows));
      } else {
        paragraphs.push(...traverseObjectToDocx(value, level + 1));
      }
    }
  }
  return paragraphs;
};

// JSON -> Markdown
const convertObjectToMarkdown = (
  obj: Record<string, any>,
  level: number = 1
): string => {
  let markdownContent = "";
  for (const [key, value] of Object.entries(obj)) {
    markdownContent += `${"#".repeat(level)} ${key}\n\n`;
    if (typeof value === "string") {
      markdownContent += `${value}\n\n`;
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        if (typeof item === "string") {
          markdownContent += `- ${item}\n`;
        } else if (typeof item === "object") {
          markdownContent += convertObjectToMarkdown(item, level + 1);
        }
      });
    } else if (typeof value === "object") {
      if (value.headers && value.rows) {
        markdownContent +=
          generateMarkdownTable(value.headers, value.rows) + "\n\n";
      } else {
        markdownContent += convertObjectToMarkdown(value, level + 1);
      }
    }
  }
  return markdownContent;
};

const generateMarkdownTable = (headers: string[], rows: string[][]): string => {
  let markdownTable = `| ${headers.join(" | ")} |\n`;
  markdownTable += `| ${headers.map(() => "---").join(" | ")} |\n`;
  rows.forEach((row) => {
    markdownTable += `| ${row.join(" | ")} |\n`;
  });
  return markdownTable;
};

interface ImportSelectionModalProps {
  onClose: () => void;
  exportData:
    | { type: "table"; exportData: TableExportData; name: string }
    | { type: "document"; exportData: DocumentExportData; name: string };
}

const ExportModal: React.FC<ImportSelectionModalProps> = ({
  onClose,
  exportData,
}) => {
  const { currentProject } = useSelector((state: RootState) => state.auth);
  const [filename, setFilename] = useState(currentProject?.name);

  const handleExportMarkdown = () => {
    if (exportData.type === "document" && exportData.exportData) {
      exportToMarkdown(exportData.exportData, filename);
      toast.success("エクスポートに成功");
    } else {
      console.error("Markdown export failed: content is undefined or null");
      toast.error("エクスポートに失敗");
    }
  };

  const handleExportDocx = () => {
    if (exportData.type === "document" && exportData.exportData) {
      exportToDocx(exportData.exportData.content, filename);
      toast.success("エクスポートに成功");
    } else {
      console.error("DOCX export failed: content is undefined or null");
      toast.error("エクスポートに失敗");
    }
  };

  const handleExportXlsx = () => {
    if (exportData.type === "table" && exportData.exportData) {
      exportToExcel(exportData.exportData, filename, exportData.name);
      toast.success("エクスポートに成功");
    } else {
      console.error("XLSX export failed: content is undefined or null");
      toast.error("エクスポートに失敗");
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>エクスポートタイプを選択</h2>
          <label className={styles.label}>ファイル名：</label>
          <input
            type="text"
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            className={styles.filenameInput}
            placeholder="ファイル名を入力"
          />
          <div className={styles.importActions}>
            {exportData.type === "document" ? (
              <>
                <button
                  className={styles.selectButton}
                  onClick={handleExportMarkdown}
                >
                  マークダウンファイル（.md）
                </button>
                <button
                  className={styles.selectButton}
                  onClick={handleExportDocx}
                >
                  Wordファイル（.docx）
                </button>
              </>
            ) : exportData.type === "table" ? (
              <>
                <button
                  className={styles.selectButton}
                  onClick={handleExportXlsx}
                >
                  エクセルファイル（.xlsx）
                </button>
              </>
            ) : null}
          </div>
          <div className={styles.actions}>
            <button className={styles.cancelButton} onClick={onClose}>
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;
