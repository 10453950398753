import { Requirements } from "../../interface/requirements";
import { ErrorBoundary } from "../../errors/errorBoundary";
import { commonHeader, endpoints } from "../../config/apiConfig";
import { toast } from "react-toastify";
import { User } from "../../interface/user";
import { Project } from "../../interface/project";
import meta from "../../stories/Button.stories";
export class RequirementsApi {
  private getRequestInfo(method: string, requestBody: object): RequestInit {
    return {
      method: method,
      headers: commonHeader,
      credentials: "include", // 型が自動的に解決される
      body: JSON.stringify(requestBody),
    };
  }

  private getRequestBody(
    content: string,
    name: string | "string",
    url: string | "string",
    user_id: string,
    project_id: string,
    organization_id: string
  ): object {
    return {
      content: content,
      metadata: {
        name: name,
        url: url,
      },
      user_id: user_id,
      project_id: project_id,
      organization_id: organization_id,
    };
  }

  async generateRequirements(
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Requirements | null> {
    const generateRequirements = endpoints.generateRequirements;

    try {
      const requestBody = this.getRequestBody(
        "",
        "string",
        "string",
        user_id,
        project_id,
        organization_id
      );
      const requestInfo = this.getRequestInfo("POST", requestBody);
      const response = await fetch(generateRequirements, requestInfo);

      if (response.ok) {
        const requirements: Requirements = await response.json();
        console.log("responseData", requirements);
        toast.success("要件定義書の生成に成功しました");
        return requirements;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async generateSystemProposalFromSummary(
    content: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Requirements | null> {
    const generateSystemProposalFromSummary = endpoints.generateFromSummary;
    const requestBody = this.getRequestBody(
      content,
      "string",
      "string",
      user_id,
      project_id,
      organization_id
    );
    const requestInfo = this.getRequestInfo("POST", requestBody);
    const response = await fetch(
      generateSystemProposalFromSummary,
      requestInfo
    );
    try {
      if (response.ok) {
        const requirements: Requirements = await response.json();
        console.log("responseData", requirements);
        toast.success("システム概要書の生成に成功しました");

        return requirements;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }
  async generateSystemProposalFromTranscription(
    content: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Requirements | null> {
    const generateSystemProposalFromTranscription =
      endpoints.generateFromTranscribe;
    const requestBody = this.getRequestBody(
      content,
      "string",
      "string",
      user_id,
      project_id,
      organization_id
    );
    const requestInfo = this.getRequestInfo("POST", requestBody);
    const response = await fetch(
      generateSystemProposalFromTranscription,
      requestInfo
    );
    try {
      if (response.ok) {
        const requirements: Requirements = await response.json();
        console.log("responseData", requirements);
        toast.success("システム概要書の生成に成功しました");

        return requirements;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async regenerateSystemProposal(
    input_document_id: string,
    user: User,
    project: Project
  ): Promise<Requirements | null> {
    const regenerateSystemProposalUrl = endpoints.regenerateSystemProposal;
    const requestBody = {
      input_document_id: input_document_id,
      metadata: {
        name: project.client.name,
        url: project.client.url,
      },
      user_id: user.id,
      project_id: project.id,
      organization_id: user.organization_id,
    };
    const requestInfo = this.getRequestInfo("POST", requestBody);
    const response = await fetch(regenerateSystemProposalUrl, requestInfo);
    try {
      if (response.ok) {
        const requirements: Requirements = await response.json();
        console.log("responseData", requirements);
        toast.success("システム概要書の生成に成功しました");

        return requirements;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async generateArchitecture(
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Requirements | null> {
    const generateArchitecture = endpoints.generateArchitecture;
    try {
      const requestBody = this.getRequestBody(
        "",
        "string",
        "string",
        user_id,
        project_id,
        organization_id
      );
      const requestInfo = this.getRequestInfo("POST", requestBody);
      const response = await fetch(generateArchitecture, requestInfo);

      if (response.ok) {
        const requirements: Requirements = await response.json();
        console.log("responseData", requirements);
        toast.success("基本設計書の生成に成功しました");

        return requirements;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }
}
