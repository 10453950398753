import { Organization } from "../interface/organization";
import { User } from "../interface/user";

export function initCurrentUser(): User {
  return {
    id: "",
    last_name: "",
    first_name: "",
    display_name: "",
    email: "",
    project_ids: [],
    organization_id: "",
  };
}

export function initOrganization(): Organization {
  return {
    id: "",
    name: "",
    url: "",
    members: [],
    generation_count: 0,
  };
}
