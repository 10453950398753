import React, { useState } from "react";
import styles from "./index.module.scss";
import ImportModal from "../importSummaryModal"; // インポートモーダルのコンポーネント

interface ImportSelectionModalProps {
  onClose: () => void;
}
const SelectionModal: React.FC<ImportSelectionModalProps> = ({ onClose }) => {
  const [selectedOption, setSelectedOption] = useState<
    "summary" | "transcribe" | null
  >(null);

  const handleCloseModal = async () => {
    setSelectedOption(null);
  };

  // 選択画面を表示
  if (!selectedOption) {
    return (
      <div className={styles.modalOverlay}>
        <div className={styles.modalContent}>
          <div className={styles.modalHeader}>
            <h2>アップロードするドキュメントタイプを選択</h2>
          </div>
          <div className={styles.importActions}>
            <button
              className={styles.selectButton}
              onClick={() => setSelectedOption("summary")}
            >
              議事録のアップロード
            </button>
            <button
              className={styles.selectButton}
              onClick={() => setSelectedOption("transcribe")}
            >
              文字起こしのアップロード
            </button>
          </div>
          <div className={styles.actions}>
            <button className={styles.cancelButton} onClick={onClose}>
              キャンセル
            </button>
          </div>
        </div>
      </div>
    );
  }

  // 選択された内容に応じて、ImportModalにプロパティを渡す
  return (
    <div>
      <ImportModal
        onClose={handleCloseModal}
        importType={selectedOption} // 選択されたタイプを渡す
      />
    </div>
  );
};

export default SelectionModal;
