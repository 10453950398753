export const apiBaseUri = process.env.REACT_APP_API_URL || '';

export const userAuthPath = process.env.REACT_APP_AUTH_URI || '';

export const apiVersion = process.env.REACT_APP_API_VERSION || '';

export const azureLoginUri = process.env.REACT_APP_AZURE_SIGNIN_URI;

export const commonHeader ={
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_KEY
    };

export const endpoints ={
    getOrganization: (id: string) => `${apiBaseUri}${apiVersion}/organization/${id}`,
    getRoleInOrganization: (id: string, userId: string) => `${apiBaseUri}${apiVersion}/organization/${id}/role?user_id=${userId}`,
    updateOrganization: `${apiBaseUri}${apiVersion}/organization/update`,
    getAllProjects:(userId: string,organization_id: string) =>
        `${apiBaseUri}${apiVersion}/user/${userId}/projects?organization_id=${organization_id}`,
    userAuth: `${apiBaseUri}${userAuthPath}`,
    createProject: `${apiBaseUri}${apiVersion}/project`,
    getDocument: (id: string, projectId: string) => `${apiBaseUri}${apiVersion}/document/get-by-id/${id}?project_id=${projectId}`,
    getAllDocuments: (type: string, projectId: string) => `${apiBaseUri}${apiVersion}/document/get-by-type/${type}?project_id=${projectId}`,
    getSummaryList:(projectId: string) => `${apiBaseUri}${apiVersion}/document/get-by-type/summary?project_id=${projectId}`,
    getSystemProposalList:(projectId: string) => `${apiBaseUri}${apiVersion}/document/get-by-type/system-proposal?project_id=${projectId}`,
    getAllDocumentsInProject: (type: string, projectId: string) => `${apiBaseUri}${apiVersion}/document/get-by-type/${type}?project_id=${projectId}`,
    getProject: (projectId: string, organizationId: string) => `${apiBaseUri}${apiVersion}/project/${projectId}?organization_id=${organizationId}`,
    getRoleInProject: (id: string, userId: string, organizationId: string) =>
        `${apiBaseUri}${apiVersion}/project/${id}/role?user_id=${userId}&organization_id=${organizationId}`,
    getInvitableUsers: (id: string, organizationId: string ) => `${apiBaseUri}${apiVersion}/project/${id}/invitable-users?organization_id=${organizationId}`,
    inviteUserToOrganization: `${apiBaseUri}${apiVersion}/organization/invite`,
    inviteInternalUserToProject: `${apiBaseUri}${apiVersion}/project/invite-internal`,
    inviteExternalUserToProject: `${apiBaseUri}${apiVersion}/project/invite-external`,
    upsertVocaburary: `${apiBaseUri}${apiVersion}/project/dictionary`,
    generateAllFromSummary:`${apiBaseUri}${apiVersion}/generate-all-from-summary`,
    userInfoUpdate: `${apiBaseUri}${apiVersion}/user/update`,
    generateAllFromTranscribe: `${apiBaseUri}${apiVersion}/generate-all-from-transcribe`,
    updateProjectDetail: `${apiBaseUri}${apiVersion}/project/update`,
    generateFromTranscribe: `${apiBaseUri}${apiVersion}/generate-from-transcribe`,
    regenerateSystemProposal: `${apiBaseUri}${apiVersion}/regenerate-system-proposal`,
    generateFromSummary: `${apiBaseUri}${apiVersion}/generate-from-summary`,
    generateRequirements: `${apiBaseUri}${apiVersion}/generate-requirements`,
    generateArchitecture:`${apiBaseUri}${apiVersion}/generate-architecture`,
    deleteProject: (user_id: string, project_id: string, organization_id: string) => `${apiBaseUri}${apiVersion}/project/${project_id}/delete?user=${user_id}&organization=${organization_id}`,
    logoutApp: `${apiBaseUri}/logout`,
    getUserByEmail: `${apiBaseUri}${apiVersion}/user?email=sample@sample.com`,
    updateDocument: `${apiBaseUri}${apiVersion}/document/update`,
    lockDocument: `${apiBaseUri}${apiVersion}/document/lock`,
    unlockDocument: `${apiBaseUri}${apiVersion}/document/unlock`,
    updateDocumentInfo: `${apiBaseUri}${apiVersion}/document/update-info`,
    deleteDocument: (id: string, user_id: string, project_id: string, organization_id: string) => `${apiBaseUri}${apiVersion}/document/${id}/delete?user_id=${user_id}&project_id=${project_id}&organization_id=${organization_id}`
    }