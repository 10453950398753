import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/es/storage/session";
import authReducer from "./slices/auth";

const persistConfig = {
  key: "root", // ストレージに保存するキー
  storage: sessionStorage, // 使用するストレージ
};

// authReducerを永続化するために、persistReducerを使用
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
  },
});

// persistorの作成
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
