import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { ColumnDef } from "@tanstack/react-table";
import ListContent from "../listContent";
import styles from "./index.module.scss";

interface TabsComponentProps {
  tabTitles: string[];
  dataSets: object[][];
  columns: Array<ColumnDef<object>[]>;
  //updateData: (rowIndex: number, columnId: string, value: string | number, tabIndex: number) => void;
}

const TabsList = ({ dataSets, columns, tabTitles }: TabsComponentProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Tabs
      className={styles["tab"]}
      selectedIndex={selectedIndex}
      onSelect={(index) => setSelectedIndex(index)}
    >
      <TabList className={styles["tab-list"]}>
        {tabTitles.map((title, index) => (
          <Tab
            key={index}
            className={styles["tab-list-name"]}
            selectedClassName={styles["tab-list-name--selected"]}
          >
            {title}
          </Tab>
        ))}
      </TabList>

      {dataSets.map((data, index) => (
        <TabPanel key={index} className={styles["custom-tab-panel"]}>
          <ListContent
            data={data}
            columns={columns[index]}
            //updateData={(rowIndex, columnId, value) => updateData(rowIndex, columnId, value, index)}
            tabTitle={tabTitles[index]}
          />
        </TabPanel>
      ))}
    </Tabs>
  );
};

export default TabsList;
