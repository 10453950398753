// CreateProjectModalContent.tsx
import React from "react";
import FormField from "../formField";
import Button from "../button";
import styles from "./index.module.scss";

interface ProjectModalContentProps {
  projectData: any; // Adjust this to the type of your project data
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInputDescriptionChange: (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleCreate?: () => void;
  handleUpdateProject?: () => void;
  closeModal: () => void;
  isFormValid: boolean;
  isLock: boolean;
  startDateString: string;
  endDateString: string;
  buttonLabel: string;
  isEditMode: boolean; // To distinguish between create and update modes
}

interface FormFieldConfig {
  label: string;
  name: string;
  type: "text" | "textarea" | "date"; // Explicit type for `type`
  required: boolean;
  placeholder?: string;
  value: string;
}

const ProjectModalContent: React.FC<ProjectModalContentProps> = ({
  projectData,
  handleInputChange,
  handleInputDescriptionChange,
  handleCreate,
  handleUpdateProject,
  closeModal,
  isFormValid,
  isLock,
  startDateString,
  endDateString,
  buttonLabel,
  isEditMode,
}) => {
  const formFields: FormFieldConfig[] = [
    {
      label: "プロジェクト名",
      name: "name",
      type: "text",
      required: true,
      value: projectData.name ?? "",
    },
    {
      label: "説明",
      name: "description",
      type: "textarea",
      required: false,
      value: projectData.description ?? "",
    },
    {
      label: "契約開始日",
      name: "start_date",
      type: "date",
      required: true,
      value: startDateString || "",
    },
    {
      label: "契約終了日",
      name: "end_date",
      type: "date",
      required: true,
      value: endDateString || "",
    },
    {
      label: "クライアント名",
      name: "client_name",
      type: "text",
      required: false,
      value: projectData.client_name ?? "",
    },
    {
      label: "クライアントURL",
      name: "client_url",
      type: "text",
      required: false,
      value: projectData.client_url ?? "",
    },
  ];

  return (
    <div>
      <form className={styles.content}>
        {formFields.map((field, index) => {
          if (field.name === "start_date") {
            return (
              <div className={styles.dateFieldsContainer} key="date-fields">
                <FormField
                  label={formFields[index].label}
                  name={formFields[index].name}
                  type={formFields[index].type}
                  required={formFields[index].required}
                  value={formFields[index].value}
                  onChange={handleInputChange}
                />
                <FormField
                  label={formFields[index + 1].label} // Access end_date (next field in the array)
                  name={formFields[index + 1].name}
                  type={formFields[index + 1].type}
                  required={formFields[index + 1].required}
                  value={formFields[index + 1].value}
                  onChange={handleInputChange}
                />
              </div>
            );
          }

          if (field.name === "end_date") {
            return null;
          }

          return (
            <FormField
              key={field.name}
              label={field.label}
              name={field.name}
              type={field.type}
              required={field.required}
              value={field.value}
              onChange={
                field.name === "description"
                  ? isEditMode
                    ? handleInputChange
                    : handleInputDescriptionChange
                  : handleInputChange
              }
            />
          );
        })}
      </form>

      <div className={styles.buttonContainer}>
        <Button label="キャンセル" styleType="white" onClick={closeModal} />
        <Button
          label={buttonLabel}
          styleType="blue"
          onClick={isEditMode ? handleUpdateProject : handleCreate}
          disabled={!isFormValid || isLock}
        />
      </div>
    </div>
  );
};

export default ProjectModalContent;
