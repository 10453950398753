import React, { useEffect, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { DocumentApi } from "../../functions/api/document";
import { toast } from "react-toastify";
import ExportModal from "../../components/exportModal";
import { TableExportData } from "../../components/exportModal";
import { useNavigationPrompt } from "../../functions/navigationHooks";
import { ReactComponent as DeleteIcon } from "../../images/delete_row.svg";
import TabsComponent from "../../components/tabs";

/// データの型定義
interface FieldData {
  app_id: string;
  fid: string;
  フィールド名: string;
  フィールドコード: string;
  フィールドの種類: string;
  フィールドタイプ: string;
  必須可否: string;
  重複可否: string;
  選択項目: string;
  管理目的: string;
}

interface AppConnecterData {
  cid: string;
  sid: string;
  tid: string;
  fieldInSid: string;
  fieldInTid: string;
  relationship: string;
}

function createFieldData(
  app_id: string,
  fid: string,
  フィールド名: string,
  フィールドコード: string,
  フィールドの種類: string,
  フィールドタイプ: string,
  必須可否: string,
  重複可否: string,
  選択項目: string,
  管理目的: string
): FieldData {
  const fieldData: FieldData = {
    app_id,
    fid,
    フィールド名,
    フィールドコード,
    フィールドの種類,
    フィールドタイプ,
    必須可否,
    重複可否,
    選択項目,
    管理目的,
  };

  return fieldData;
}

const FieldDesign = () => {
  const [fieldTableData, setFieldTableData] = useState<FieldData[]>([]);
  const [appConnectorTableData, setAppConnectorData] = useState<
    AppConnecterData[]
  >([]);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0); // 現在のタブを管理
  const [previousAppConnectorData, setPreviousAppConnectorData] = useState<
    AppConnecterData[]
  >([]);
  const [previousFieldData, setPreviousFieldData] = useState<FieldData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const { currentProject, currentUser } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const fieldDesignColumns: ColumnDef<object>[] = [
    { accessorKey: "app_id", header: "appid" },
    { accessorKey: "fid", header: "fid" },
    { accessorKey: "フィールド名", header: "フィールド名" },
    { accessorKey: "フィールドコード", header: "フィールドコード" },
    { accessorKey: "フィールドの種類", header: "フィールドの種類" },
    { accessorKey: "フィールドタイプ", header: "フィールドタイプ" },
    { accessorKey: "必須可否", header: "必須可否" },
    { accessorKey: "重複可否", header: "重複可否" },
    { accessorKey: "選択項目", header: "選択項目" },
    { accessorKey: "管理目的", header: "管理目的" },
    {
      accessorKey: "delete",
      header: "",
      cell: ({ row }) =>
        isEditable ? (
          <button
            onClick={() => deleteRow(row.index, 0)}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <DeleteIcon width={16} height={16} />
          </button>
        ) : null,
    },
  ];

  const appConnectorColumns: ColumnDef<object>[] = [
    { accessorKey: "cid", header: "cid" },
    { accessorKey: "sid", header: "sid" },
    { accessorKey: "tid", header: "tid" },
    { accessorKey: "fieldInSid", header: "fieldInSid" },
    { accessorKey: "fieldInTid", header: "fieldInTid" },
    { accessorKey: "relationship", header: "relationship" },
    {
      accessorKey: "delete",
      header: "",
      cell: ({ row }) =>
        isEditable ? (
          <button
            onClick={() => deleteRow(row.index, 1)}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            <DeleteIcon width={16} height={16} />
          </button>
        ) : null,
    },
  ];

  const addNewRow = (tabIndex: number) => {
    if (tabIndex === 0) {
      const newRow: FieldData = createFieldData(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );
      setFieldTableData([...fieldTableData, newRow]);
    } else if (tabIndex === 1) {
      setAppConnectorData([
        ...appConnectorTableData,
        {
          cid: "",
          sid: "",
          tid: "",
          fieldInSid: "",
          fieldInTid: "",
          relationship: "",
        },
      ]);
    }
  };

  const getExportData = (): TableExportData => {
    const headers: string[] = [];
    const rows: string[][] = [];
    const name = "フィールド設計書";

    // フィールドテーブルのヘッダーとデータを追加
    headers.push(
      "app_id",
      "fid",
      "フィールド名",
      "フィールドコード",
      "フィールドの種類",
      "フィールドタイプ",
      "必須可否",
      "重複可否",
      "選択項目",
      "管理目的"
    );

    fieldTableData.forEach((row) => {
      rows.push([
        row.app_id,
        row.fid,
        row.フィールド名,
        row.フィールドコード,
        row.フィールドの種類,
        row.フィールドタイプ,
        row.必須可否,
        row.重複可否,
        row.選択項目,
        row.管理目的,
      ]);
    });

    // 空行を追加して次のテーブルと区切る
    rows.push([]);

    // アプリ間連携テーブルのヘッダーとデータを追加
    headers.push(
      "cid",
      "sid",
      "tid",
      "fieldInSid",
      "fieldInTid",
      "relationship"
    );
    appConnectorTableData.forEach((row) => {
      rows.push([
        row.cid,
        row.sid,
        row.tid,
        row.fieldInSid,
        row.fieldInTid,
        row.relationship,
      ]);
    });

    rows.push([]);

    return { headers, rows, name };
  };

  const deleteRow = (rowIndex: number, tabIndex: number) => {
    if (tabIndex === 0) {
      setFieldTableData(
        fieldTableData.filter((_, index) => index !== rowIndex)
      );
    } else if (tabIndex === 1) {
      setAppConnectorData(
        appConnectorTableData.filter((_, index) => index !== rowIndex)
      );
    }
  };

  const updateData = (
    rowIndex: number,
    columnId: string,
    value: string | number,
    tabIndex: number
  ) => {
    let updatedData: Record<string, any>[];
    if (tabIndex === 1) {
      // appConnectorData を更新
      updatedData = [...appConnectorTableData];
      updatedData[rowIndex][columnId] =
        typeof updatedData[rowIndex][columnId] === "number"
          ? Number(value)
          : value;
      setAppConnectorData(updatedData as AppConnecterData[]);
    } else if (tabIndex === 0) {
      // fieldDesignData を更新
      updatedData = [...fieldTableData];
      updatedData[rowIndex][columnId] =
        typeof updatedData[rowIndex][columnId] === "number"
          ? Number(value)
          : value;
      setFieldTableData(updatedData as FieldData[]);
    }
  };

  const Lock = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.management_field.id) {
      toast.error("フィールド設計書が見つかりません");
      return;
    }

    const response = await documentApi.lock(
      currentProject.requirements.contents.management_field.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("Field design is unlocked");
      setIsEditable(true);
      setPreviousAppConnectorData(
        JSON.parse(JSON.stringify(appConnectorTableData))
      );
      setPreviousFieldData(JSON.parse(JSON.stringify(fieldTableData)));
    }
  };

  const Unlock = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.management_field.id) {
      toast.error("フィールド設計書が見つかりません");
      return;
    }

    const response = await documentApi.unlock(
      currentProject.requirements.contents.management_field.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("Field design is unlocked");
      setIsEditable(false);
    }
  };

  const handleCancel = async () => {
    if (!currentProject.requirements.contents.management_field.id) {
      toast.error("フィールド設計書が見つかりません");
      return;
    }

    const documentApi = new DocumentApi();

    const response = await documentApi.unlock(
      currentProject.requirements.contents.management_field.id,
      currentUser?.id,
      currentProject.id,
      currentUser?.organization_id
    );

    if (response !== null) {
      console.log("unlock field design");
      setFieldTableData(previousFieldData);
      setAppConnectorData(previousAppConnectorData);
      setIsEditable(false);
    }
  };

  const handleEdit = async () => {
    Lock();
  };

  const handleExportClick = () => {
    setShowExportModal(true);
  };

  const handleSave = async () => {
    const documentApi = new DocumentApi();

    if (!currentProject.requirements.contents.management_field.id) {
      toast.error("フィールド設計書が見つかりません");
      return;
    }

    // アップデートするデータの整形
    else {
      const fieldTableHeaders = [
        "app_id",
        "fid",
        "フィールド名",
        "フィールドコード",
        "フィールドの種類",
        "フィールドタイプ",
        "必須可否",
        "重複可否",
        "選択項目",
        "管理目的",
      ];
      const appConnectorHeaders = [
        "cid",
        "sid",
        "tid",
        "fieldInSid",
        "fieldInTid",
        "relationship",
      ];

      const updateData = {
        フィールドテーブル: {
          headers: fieldTableHeaders,
          rows: fieldTableData.map((item) => [
            item.app_id,
            item.fid,
            item.フィールド名,
            item.フィールドコード,
            item.フィールドの種類,
            item.フィールドタイプ,
            item.必須可否,
            item.重複可否,
            item.選択項目,
            item.管理目的,
          ]),
        },
        アプリ間連携テーブル: {
          headers: appConnectorHeaders,
          rows: appConnectorTableData.map((item) => [
            item.cid,
            item.sid,
            item.tid,
            item.fieldInSid,
            item.fieldInTid,
            item.relationship,
          ]),
        },
      };

      const response = await documentApi.update(
        currentProject.requirements.contents.management_field.id,
        currentUser?.id,
        currentProject.id,
        currentUser?.organization_id,
        updateData
      );

      if (response !== null) {
        setIsEditable(false);
        setPreviousAppConnectorData(
          JSON.parse(JSON.stringify(appConnectorTableData))
        );
        setPreviousFieldData(JSON.parse(JSON.stringify(fieldTableData)));
      }
    }
  };

  useEffect(() => {
    if (currentProject?.requirements.contents.management_field?.id == null) {
      toast.error("フィールド設計書は生成されていません");
      return navigate("/project-top");
    }

    const fetchManagementField = async () => {
      setIsLoading(true);
      if (!currentProject.requirements.contents.management_field.id) {
        toast.error("フィールド設計書が見つかりません");
        return;
      }

      const documentApi = new DocumentApi();

      const response = await documentApi.get(
        currentProject.requirements.contents.management_field.id,
        currentProject.id
      );

      if (response !== null) {
        const rawManagementFieldData = response.content;

        const parsedManagementFieldData = JSON.parse(rawManagementFieldData);
        console.log(parsedManagementFieldData);
        const fieldTableData =
          parsedManagementFieldData["フィールドテーブル"]["rows"];
        const appTableData =
          parsedManagementFieldData["アプリ間連携テーブル"]["rows"];
        const formattedFieldData: FieldData[] = fieldTableData.map(
          (item: any) => ({
            app_id: item[0] || "",
            fid: item[1] || "",
            フィールド名: item[2] || "",
            フィールドコード: item[3] || "",
            フィールドの種類: item[4] || "",
            フィールドタイプ: item[5] || "",
            必須可否: item[6] || "",
            重複可否: item[7] || "",
            選択項目: item[8] || "",
            管理目的: item[9] || "",
          })
        );

        const formattedConnectorData: AppConnecterData[] = appTableData.map(
          (item: any) => ({
            cid: item[0],
            sid: item[1],
            tid: item[2],
            fieldInSid: item[3],
            fieldInTid: item[4],
            relationship: item[5],
          })
        );
        setFieldTableData(formattedFieldData);
        setAppConnectorData(formattedConnectorData);
        setPreviousAppConnectorData(
          JSON.parse(JSON.stringify(appConnectorTableData))
        );
        setPreviousFieldData(JSON.parse(JSON.stringify(fieldTableData)));
        setIsLoading(false); // ローディング完了
      } else {
        setIsLoading(false); // ローディング終了
        navigate("/project-top");
      }
    };

    fetchManagementField(); // コンポーネントのマウント時にデータをフェッチ
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isEditable) {
        if (!currentProject.requirements.contents.management_field.id) {
          toast.error("フィールド設計書が見つかりません");
          return;
        }

        const documentApi = new DocumentApi();

        // ロック解除処理
        documentApi.unlock(
          currentProject.requirements.contents.management_field.id,
          currentUser?.id,
          currentProject.id,
          currentUser?.organization_id
        );
        event.preventDefault();
        event.returnValue = ""; // Chrome用: 空文字を指定
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditable, currentUser, currentProject]);

  // 編集中の場合、ナビゲーションをブロック
  useNavigationPrompt(
    isEditable,
    "編集内容が保存されていません。ページを離れてもよろしいですか？",
    handleSave,
    Unlock
  );

  if (isLoading) {
    return <div>ロード中...</div>;
  }
  return (
    <>
      <h1>フィールド設計書</h1>
      {showExportModal && (
        <ExportModal
          onClose={() => setShowExportModal(false)}
          exportData={{
            type: "table",
            exportData: getExportData(),
            name: "field-design",
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        }}
      >
        {isEditable && (
          <Button
            onClick={handleCancel}
            styleType="white"
            label={"キャンセル"}
          />
        )}
        {isEditable ? (
          <Button
            onClick={handleSave}
            styleType="blue-generation-each-document"
            label="保存"
          />
        ) : (
          <Button
            onClick={handleEdit}
            styleType="blue-generation-each-document"
            label="編集"
          />
        )}
        <Button
          label="エクスポート"
          onClick={handleExportClick}
          styleType="blue"
          disabled={isEditable}
        />
      </div>
      <TabsComponent
        tabTitles={["フィールドテーブル", "アプリ間連携テーブル"]}
        dataSets={[fieldTableData, appConnectorTableData]}
        columns={[fieldDesignColumns, appConnectorColumns]}
        updateData={updateData}
        isEditable={isEditable}
        onTabChange={setCurrentTabIndex}
      />
      {isEditable && (
        <Button
          label="行を追加"
          onClick={() => addNewRow(currentTabIndex)}
          styleType="blue"
        />
      )}
    </>
  );
};

export default FieldDesign;
