import React from "react";
import styles from "./index.module.scss";

interface FormFieldProps {
  label: string;
  name: string;
  type?: "text" | "textarea" | "date"; // Support text, textarea, and date inputs
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (e: any) => void;
}

const FormField: React.FC<FormFieldProps> = ({
  label,
  name,
  type = "text",
  placeholder = "",
  required = false,
  value,
  onChange,
}) => {
  return (
    <div className={styles.formField}>
      <label>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      {type === "textarea" ? (
        <textarea
          name={name}
          placeholder={placeholder}
          className={styles.inputField}
          onChange={onChange}
          value={value}
        />
      ) : (
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          className={styles.inputField}
          onChange={onChange}
          value={value}
        />
      )}
    </div>
  );
};

export default FormField;
