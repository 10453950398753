import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../interface/user";
import { Organization } from "../interface/organization";
import { Dictionary, Project } from "../interface/project";
import { Requirements } from "../interface/requirements";
import { UserApi } from "../functions/api/user";
import { initCurrentUser, initOrganization } from "../functions/functions";

export const fetchUserInfo = createAsyncThunk(
  "auth/fetchUserInfo",
  async (_, thunkAPI) => {
    const userApi = new UserApi();

    const response = await userApi.getByEmail();

    if (response !== null) {
      const data = await response.json();
      return data[0];
    } else {
      throw new Error("ユーザー情報取得に失敗しました");
    }
  }
);

export const fetchRole = createAsyncThunk(
  "auth/fetchRole",
  async (user: User, thunkAPI) => {
    const userApi = new UserApi();

    const response = await userApi.getRoleInOrganization(
      user.id,
      user.organization_id
    );

    if (response !== null) {
      const roleData = await response.json();
      return roleData;
    } else {
      return thunkAPI.rejectWithValue({
        message: "ロール情報取得エラー",
      });
    }
  }
);

interface Role {
  currentOrganizationRole: string | "admin" | "member" | "manager" | null;
  currentProjectRole: string | "owner" | "manager" | "member" | null;
}

// authスライス
export interface AuthState {
  currentUser: User;
  role: Role | null;
  status: "idle" | "loading" | "failed";
  currentProject: Project;
  organization: Organization;
}

const initialState: AuthState = {
  currentUser: initCurrentUser(),
  status: "idle",
  currentProject: {
    id: "",
    name: "",
    description: "",
    start_date: new Date(),
    end_date: new Date(),
    client: {
      name: "",
      url: "",
    },
    members: [],
    modified_at: null,
    generation_status: "ready",
    generation_count: 0,
    dictionary: [],
    requirements: {
      id: "",
      contents: {
        system_proposal: {
          id: null,
          status: "ready",
        },
        functional_requirement: {
          id: null,
          status: "ready",
        },
        non_functional_requirement: {
          id: null,
          status: "ready",
        },
        work_flow: {
          id: null,
          status: "ready",
        },
        app_design: {
          id: null,
          status: "ready",
        },
        plugin_proposal: {
          id: null,
          status: "ready",
        },
        management_field: {
          id: null,
          status: "ready",
        },
        system_architecture: {
          id: null,
          status: "ready",
        },
        entity_relation: {
          id: null,
          status: "ready",
        },
      },
      modified_at: new Date(),
      project_id: "",
      input_document_id: "",
    },
    organization_id: "",
  },
  role: { currentOrganizationRole: null, currentProjectRole: null },
  organization: initOrganization(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.currentUser = initCurrentUser();
      state.role = null;
      state.organization = initOrganization();
    },
    setCurrentProject(state, action) {
      state.currentProject = action.payload;
    },
    setCurrentProjectRequirements(state, action: PayloadAction<Requirements>) {
      if (state.currentProject) {
        state.currentProject.requirements = action.payload;
      }
    },
    setCurrentProjectDictionary(state, action: PayloadAction<Dictionary[]>) {
      if (state.currentProject) {
        state.currentProject.dictionary = action.payload;
      }
    },
    setOrganization(state, action: PayloadAction<Organization>) {
      state.organization = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload) state.currentUser = action.payload;
        if (!state.currentUser) {
          state.status = "failed";
          return;
        }

        state.status = "idle";
      })
      .addCase(fetchUserInfo.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchRole.fulfilled, (state, action: PayloadAction<string>) => {
        // state.role が null の場合に空のオブジェクトを設定
        state.role = state.role || {
          currentOrganizationRole: null,
          currentProjectRole: null,
        };
        state.role.currentOrganizationRole = action.payload;
      });
  },
});

export const {
  clearAuth,
  setCurrentProject,
  setCurrentProjectRequirements,
  setCurrentProjectDictionary,
  setOrganization,
} = authSlice.actions;
export default authSlice.reducer;
