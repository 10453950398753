import { toast } from "react-toastify";
import { endpoints, commonHeader } from "../../config/apiConfig";
import { ErrorBoundary } from "../../errors/errorBoundary";
import {
  Document,
  LockDocumentRequestBody,
  UpdateDocumentRequestBody,
  UnlockDocumentRequestBody,
  UpdateDocumentInfoRequestBody,
} from "../../interface/document";

export class DocumentApi {
  private getRequestInfo(
    method: string,
    requestBody?:
      | LockDocumentRequestBody
      | UnlockDocumentRequestBody
      | UpdateDocumentRequestBody
      | UpdateDocumentInfoRequestBody
  ): RequestInit {
    return {
      method: method,
      headers: commonHeader,
      credentials: "include",
      keepalive: true,
      ...{
        body: JSON.stringify(requestBody),
      },
    };
  }

  private getUpdateRequestBody(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string,
    updateData: object | string
  ): UpdateDocumentRequestBody {
    // ベースとなる共通データと、メソッドごとの追加データをマージ
    return {
      id: id,
      user_id: user_id,
      project_id: project_id,
      organization_id: organization_id,
      content: JSON.stringify(updateData),
    };
  }

  private getLockRequestBody(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): LockDocumentRequestBody {
    return {
      id: id,
      user_id: user_id,
      project_id: project_id,
      organization_id: organization_id,
    };
  }

  private getUnlockRequestBody(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): UnlockDocumentRequestBody {
    return {
      id: id,
      user_id: user_id,
      project_id: project_id,
      organization_id: organization_id,
    };
  }

  private getUpdateInfoRequestBody(
    id: string,
    name: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): UpdateDocumentInfoRequestBody {
    return {
      id: id,
      name: name,
      user_id: user_id,
      project_id: project_id,
      organization_id: organization_id,
    };
  }

  async lock(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Response | null> {
    const lockDocument = endpoints.lockDocument;

    try {
      const requestBody = this.getLockRequestBody(
        id,
        user_id,
        project_id,
        organization_id
      );
      const requestInfo = this.getRequestInfo("POST", requestBody);

      const response = await fetch(lockDocument, requestInfo);
      if (response.ok) {
        console.log("document lock request is succeeded");
        return response;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async unlock(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Response | null> {
    const unlockDocument = endpoints.unlockDocument;

    try {
      const requestBody = this.getUnlockRequestBody(
        id,
        user_id,
        project_id,
        organization_id
      );
      const requestInfo = this.getRequestInfo("POST", requestBody);

      const response = await fetch(unlockDocument, requestInfo);
      if (response.ok) {
        console.log("functional requirement is locked");
        return response;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async update(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string,
    updateData: object | string
  ): Promise<Response | null> {
    const updateDocument = endpoints.updateDocument;

    try {
      const requestBody = this.getUpdateRequestBody(
        id,
        user_id,
        project_id,
        organization_id,
        updateData
      );

      const requestInfo = this.getRequestInfo("POST", requestBody);

      const response = await fetch(updateDocument, requestInfo);

      if (response.ok) {
        toast.success("保存に成功しました");
        return response;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async get(id: string, project_id: string): Promise<Document | null> {
    const getDocument = endpoints.getDocument(id, project_id);

    try {
      const requestInfo = this.getRequestInfo("GET");

      const response = await fetch(getDocument, requestInfo);

      if (response.ok) {
        const data: Document = await response.json();

        return data;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async getAll(type: string, project_id: string): Promise<Document[] | null> {
    const getAllDocuments = endpoints.getAllDocuments(type, project_id);

    try {
      const requestInfo = this.getRequestInfo("GET");
      const response = await fetch(getAllDocuments, requestInfo);

      if (response.ok) {
        const data: Document[] = await response.json();
        return data;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async updateInfo(
    id: string,
    name: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ) {
    const updateInfo = endpoints.updateDocumentInfo;

    try {
      const requestBody = this.getUpdateInfoRequestBody(
        id,
        name,
        user_id,
        project_id,
        organization_id
      );
      const requestInfo = this.getRequestInfo("POST", requestBody);
      const response = await fetch(updateInfo, requestInfo);

      if (response.ok) {
        const data: Document[] = await response.json();
        return data;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }

  async delete(
    id: string,
    user_id: string,
    project_id: string,
    organization_id: string
  ): Promise<Response | null> {
    const deleteDocumentUrl = endpoints.deleteDocument(
      id,
      user_id,
      project_id,
      organization_id
    );
    try {
      const requestInfo = this.getRequestInfo("DELETE");
      const response = await fetch(deleteDocumentUrl, requestInfo);

      if (response.ok) {
        console.log(`Document id ${id} successfully deleted.`);
        toast.success("削除に成功");
        return response;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }
}
