import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../../store"; // Reduxの型
import OutputGenerationStatusDisplay from "../../components/outputGenerationStatusDisplay";
import ProjectTopTabs from "../../components/projectTopTabs";
import { ColumnDef } from "@tanstack/react-table";
import { setCurrentProject } from "../../slices/auth";
import InviteProjectModal from "../../components/inviteProjectModal";
import { Project } from "../../interface/project";
import OnProjectCreatedModal from "../../components/onProjectCreatedModal";
import { ProjectApi } from "../../functions/api/project";
import { azureLoginUri } from "../../config/apiConfig";
import { toast } from "react-toastify";

interface DocumentNodeStatus {
  id: string;
  status: "ready" | "in_progress" | "completed";
}

// データを更新する関数
const updateData = (
  rowIndex: number,
  columnId: string,
  value: string | number,
  tabIndex: number
) => {
  console.log(
    `Tab ${tabIndex} の行 ${rowIndex} の列 ${columnId} を ${value} に更新`
  );
};

const ProjectTop = () => {
  const { currentProject, currentUser } = useSelector(
    (state: RootState) => state.auth
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isNewlyCreated, setIsNewlyCreated] = useState(false);
  const [documentStatus, setDocumentStatus] = useState<DocumentNodeStatus[]>(
    []
  );
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const [dataSets, setDataSets] = useState<object[][]>([
    [], // トップタブ用のデータ（空配列）
    [
      { property: "プロジェクト名", description: "" },
      { property: "概要", description: "" },
      { property: "契約期間", description: "" },
      { property: "担当者", description: "" },
      { property: "クライアント企業URL", description: "" },
    ],
    [{ displayName: "", role: "" }],
  ]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const handleRenameMemberRole = (role: string) => {
    switch (role) {
      case "owner":
        return "オーナー";
      case "contributor":
        return "編集者";
      case "member":
        return "閲覧者";
      default:
        return role;
    }
  };

  const handleGetProject = async (
    projectId: string,
    organizationId: string
  ) => {
    const projectApi = new ProjectApi();

    const response = await projectApi.getOne(projectId, organizationId);

    if (response !== null) {
      const data = await response.json();
      return data;
    } else {
      toast.error("再ログインしてください");
      window.location.href = azureLoginUri;
    }
  };

  const handleCloneOnProjectCreated = async () => {
    setIsNewlyCreated(false);
    searchParams.delete("new");
  };

  useEffect(() => {
    const isNew = searchParams.get("new");
    console.log("new", isNew);
    if (isNew === "true") {
      setIsNewlyCreated(true);
    }
  }, [searchParams]);

  useEffect(() => {
    setIsLoading(true);

    const fetchProjectData = async () => {
      if (!currentProject?.id || !currentUser?.organization_id) {
        navigate("/signin");
        return;
      }

      setIsLoading(true);

      const project: Project = await handleGetProject(
        currentProject.id,
        currentUser?.organization_id
      );
      if (project) {
        dispatch(setCurrentProject(project));
        const newMembersData = project.members.map((member) => ({
          displayName: member.user.display_name,
          role: handleRenameMemberRole(member.role),
        }));

        setDataSets([
          [], // トップタブのデータ
          [
            { property: "プロジェクト名", description: project.name },
            { property: "概要", description: project.description },
            {
              property: "契約期間",
              description: `${
                typeof project.start_date === "string"
                  ? new Date(project.start_date).toISOString().split("T")[0]
                  : project.start_date instanceof Date
                  ? project.start_date.toISOString().split("T")[0]
                  : project.start_date
              } ～ ${
                typeof project.end_date === "string"
                  ? new Date(project.end_date).toISOString().split("T")[0]
                  : project.end_date instanceof Date
                  ? project.end_date.toISOString().split("T")[0]
                  : project.end_date
              }`,
            },
            { property: "担当者", description: project.client.name },
            {
              property: "クライアント企業URL",
              description: project.client.url,
            },
          ],
          newMembersData,
        ]);

        const updatedDocumentStatus: DocumentNodeStatus[] = [
          {
            id: "app_design",
            status: validateStatus(
              project.requirements.contents?.app_design?.status
            ),
          },
          {
            id: "entity_relation",
            status: validateStatus(
              project.requirements.contents?.entity_relation?.status
            ),
          },
          {
            id: "functional_requirement",
            status: validateStatus(
              project.requirements.contents?.functional_requirement?.status
            ),
          },
          {
            id: "field_design",
            status: validateStatus(
              project.requirements.contents?.management_field?.status
            ),
          },
          {
            id: "non_functional_requirement",
            status: validateStatus(
              project.requirements.contents?.non_functional_requirement?.status
            ),
          },
          {
            id: "plugin_proposal",
            status: validateStatus(
              project.requirements.contents?.plugin_proposal?.status
            ),
          },
          {
            id: "system_architecture",
            status: "ready",
          },
          {
            id: "system_proposal",
            status: validateStatus(
              project.requirements.contents?.system_proposal?.status
            ),
          },
          {
            id: "work_flow",
            status: validateStatus(
              project.requirements.contents?.work_flow?.status
            ),
          },
        ];

        setDocumentStatus(updatedDocumentStatus);
        setIsLoading(false);
      }
    };

    fetchProjectData();
  }, []);

  // ステータスをバリデーションして適切な値を返す
  const validateStatus = (
    status: string | undefined
  ): "ready" | "in_progress" | "completed" => {
    const validStatuses = ["ready", "in_progress", "completed"];
    return validStatuses.includes(status || "")
      ? (status as "ready" | "in_progress" | "completed")
      : "in_progress";
  };

  if (isLoading) {
    return <div>ロード中...</div>;
  }

  if (!currentProject) {
    return <div>プロジェクトが見つかりません。</div>;
  }

  // サンプルデータ
  const tabTitles = ["ダッシュボード", "詳細情報", `メンバー`];

  // 列の定義
  const columns: Array<ColumnDef<object>[]> = [
    [
      /* トップタブ用の列 */
    ],
    [
      {
        header: "項目名",
        accessorKey: "property",
      },
      {
        header: "値",
        accessorKey: "description",
      },
    ],
    [
      {
        header: "表示名",
        accessorKey: "displayName",
      },
      {
        header: "権限",
        accessorKey: "role",
      },
    ],
  ];

  return (
    <div>
      {isNewlyCreated && (
        <OnProjectCreatedModal
          label={"プロジェクトが作成されました🎉"}
          isOpen={isNewlyCreated}
          onClose={handleCloneOnProjectCreated}
        />
      )}
      <h1>{currentProject.name}</h1>
      {isOpen && (
        <InviteProjectModal
          isAdmin={true}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
      <div>
        期間:{" "}
        {currentProject?.start_date
          ? new Date(currentProject.start_date).toISOString().split("T")[0]
          : "unknown"}
        ~
        {currentProject?.end_date
          ? new Date(currentProject.end_date).toISOString().split("T")[0]
          : "unknown"}
      </div>
      <ProjectTopTabs
        tabTitles={tabTitles}
        dataSets={dataSets}
        columns={columns}
        updateData={updateData}
        onTabChange={handleTabChange}
      />
      {activeTab === 0 && (
        <div>
          <OutputGenerationStatusDisplay statusUpdates={documentStatus} />
        </div>
      )}
    </div>
  );
};

export default ProjectTop;
