import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import styles from "./index.module.scss";
import ProjectModalContent from "../projectModalContent";
import Modal from "../modal";
import Button from "../button";
import { RootState } from "../../store"; // Reduxの型
import { setCurrentProject } from "../../slices/auth";
import DeleteProjectModal from "../deleteProjectModal";
import {
  Project,
  ProjectOne,
  CreateProject,
  ProjectMember,
} from "../../interface/project";
import { ReactComponent as ContextMenu } from "../../images/context_menu.svg"; // SVGファイルをReactコンポーネントとしてインポート
import { ReactComponent as MembersIcon } from "../../images/more_than_three_members.svg";
import { ReactComponent as SingleMemberIcon } from "../../images/single_member.svg";
import { ReactComponent as ArrowDropDownClose } from "../../images/arrow_drop_down_close.svg";
import { ProjectApi } from "../../functions/api/project";

interface MemberProps {
  members: ProjectMember[];
  SingleMemberIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  MembersIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const Member: React.FC<MemberProps> = ({
  members,
  SingleMemberIcon,
  MembersIcon,
}) => {
  const displayMembers = members.slice(0, 3); // 最大3人分のアイコンを表示

  return (
    <div>
      {members.length > 2 ? (
        <MembersIcon />
      ) : (
        displayMembers.map((_, index) => <SingleMemberIcon />)
      )}
    </div>
  );
};

const ProjectList: React.FC = () => {
  const [projects, setProjects] = useState<ProjectOne[]>([]); // プロジェクトリストの型定義
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] =
    useState(false);
  const [selectedProject, setSelectedProject] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({ key: "", direction: "asc" });
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLock, setIsLock] = useState(false);
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser); // Reduxからuser情報を取得
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [newProject, setNewProject] = useState<CreateProject>({
    name: "",
    description: "",
    start_date: new Date(),
    end_date: new Date(),
    client_name: "",
    client_url: "",
    user_id: "",
    organization_id: "",
  });

  // モーダルを開く関数
  const openModal = () => {
    setModalOpen(true);
  };

  // モーダルを閉じる関数
  const closeModal = () => {
    setModalOpen(false);
  };

  // API連携を伴うプロジェクト作成関数
  const handleCreateProject = async (createProject: CreateProject) => {
    if (
      currentUser?.id === undefined ||
      currentUser?.organization_id === undefined
    ) {
      return null;
    }
    const projectData: CreateProject = {
      name: createProject.name,
      description: createProject.description,
      start_date: createProject.start_date,
      end_date: createProject.end_date,
      client_name: createProject.client_name,
      client_url: createProject.client_url,
      user_id: currentUser?.id,
      organization_id: currentUser?.organization_id,
    };

    const projectApi = new ProjectApi();

    const response = await projectApi.create(projectData);

    if (response !== null) {
      const data = await response.json();
      // 日本時間の現在時刻を取得してISO形式に変換
      const currentJSTDate = new Date();
      const jstOffset = 9 * 60 * 60 * 1000; // 9時間 (日本時間) のオフセット
      const modifiedAtJST = new Date(currentJSTDate.getTime() + jstOffset)
        .toISOString()
        .replace("Z", "+09:00"); // 日本時間表記に整形

      // modified_atフィールドを追加
      const newProject = { ...data, modified_at: modifiedAtJST };

      setProjects([...projects, newProject]); // プロジェクトリストに新規プロジェクトを追加
      return newProject;
    } else {
      toast.error("必須項目を入力してください");
      console.error("プロジェクト作成に失敗しました:");
      return null;
    }
  };

  const handleGetAllProjects = useCallback(async () => {
    const projectApi = new ProjectApi();

    const response = await projectApi.getAll(
      currentUser.id,
      currentUser.organization_id
    );

    if (response !== null) {
      const data = await response.json(); // 成功した場合のレスポンスデータ
      console.log("Projects:", data);
      // プロジェクトのデータに members の長さを追加
      if (Array.isArray(data)) {
        const projectsWithMemberCount = data?.map((project: ProjectOne) => ({
          name: project.name,
          start_date: project.start_date,
          end_date: project.end_date,
          modified_at: project.modified_at,
          user_id: currentUser.id,
          members: project.members, // メンバーの数を追加
          id: project.id,
        }));
        setProjects(projectsWithMemberCount); // プロジェクトリストをセット
      }
    } else {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    // コンポーネントがマウントされたらAPIリクエストを呼び出す
    const fetchProjects = async () => {
      setIsLoading(true); // リクエスト開始時にローディング
      await handleGetAllProjects();
      setIsLoading(false); // リクエスト完了後にローディング終了
    };
    fetchProjects();
  }, [handleGetAllProjects]); // userが変わったら再度リクエストを送信

  const handleProjectSelect = async (projectId: string) => {
    if (currentUser?.id && currentUser?.organization_id) {
      const response: Project = await handleGetProject(projectId);
      console.log("Get project", response);
      dispatch(setCurrentProject(response));
      navigate("/project-top");
    } else {
      navigate("/signin");
    }
  };

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = [...projects].sort((a, b) => {
    if (sortConfig.key === "name") {
      return sortConfig.direction === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortConfig.key === "start_date") {
      return sortConfig.direction === "asc"
        ? new Date(a.start_date || 0).getTime() -
            new Date(b.start_date || 0).getTime()
        : new Date(b.start_date || 0).getTime() -
            new Date(a.start_date || 0).getTime();
    } else if (sortConfig.key === "modified_at") {
      return sortConfig.direction === "asc"
        ? new Date(a.modified_at || 0).getTime() -
            new Date(b.modified_at || 0).getTime()
        : new Date(b.modified_at || 0).getTime() -
            new Date(a.modified_at || 0).getTime();
    } else if (sortConfig.key === "members") {
      return sortConfig.direction === "asc"
        ? a.members.length - b.members.length
        : b.members.length - a.members.length;
    }
    return 0;
  });

  const handleGetProject = async (projectId: string) => {
    if (currentUser?.organization_id == null) {
      toast.error("ユーザーが見つかりませんでした");
      return null;
    }

    const projectApi = new ProjectApi();

    const response = await projectApi.getOne(
      projectId,
      currentUser?.organization_id
    );
    if (response !== null) {
      const data = await response.json();
      return data;
    }
  };

  // 入力変更時のハンドラ
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "client_name" || name === "client_url") {
      setNewProject((prevProject) => ({
        ...prevProject,
        [name]: value,
      }));
    } else {
      // 他のプロパティを更新する処理
      setNewProject((prevProject) => ({
        ...prevProject,
        [name]:
          name === "start_date" || name === "end_date"
            ? new Date(value)
            : value,
      }));
    }
  };

  // 入力変更時のテキストエリアのハンドラ
  const handleInputDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // 作成ボタンのハンドラ
  const handleCreate = async () => {
    setIsLock(true);
    const response = await handleCreateProject(newProject);
    if (!response) {
      return;
    }

    const project: Project = response;
    dispatch(setCurrentProject(project));
    navigate("/project-top?new=true");

    closeModal();
    setIsLock(false);
  };

  useEffect(() => {
    // 必須項目がすべて入力されているかをチェックし、boolean 型に変換
    const isFormFilled = Boolean(
      newProject.name && newProject.start_date && newProject.end_date
    );

    setIsFormValid(isFormFilled);
  }, [newProject]);

  if (isLoading) {
    return <div>ロード中...</div>;
  }
  const handleOpenModal = (projectId: string, projectName: string) => {
    setSelectedProject({ id: projectId, name: projectName });
    setIsDeleteProjectModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteProjectModalOpen(false);
    setSelectedProject(null);
    handleGetAllProjects(); // プロジェクトリストを再取得
  };

  const modalContent = (
    <ProjectModalContent
      projectData={newProject}
      handleInputChange={handleInputChange}
      handleInputDescriptionChange={handleInputDescriptionChange}
      handleCreate={handleCreate}
      closeModal={closeModal}
      isFormValid={isFormValid}
      isLock={isLock}
      startDateString={
        newProject.start_date instanceof Date
          ? newProject.start_date.toISOString().split("T")[0]
          : ""
      }
      endDateString={
        newProject.end_date instanceof Date
          ? newProject.end_date.toISOString().split("T")[0]
          : ""
      }
      buttonLabel={!isLock ? "作成" : "作成中"}
      isEditMode={false}
    />
  );

  return (
    <div>
      <div className={styles.title}>
        <h1>プロジェクト</h1>
        <Button
          label="新規作成"
          styleType="create-project"
          onClick={openModal}
        />
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              プロジェクト名
              <button onClick={() => handleSort("name")}>
                <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
              </button>
            </th>
            <th>
              契約期間
              <button onClick={() => handleSort("start_date")}>
                <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
              </button>
            </th>
            <th>
              更新日時
              <button onClick={() => handleSort("modified_at")}>
                <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
              </button>
            </th>
            <th>
              メンバー
              <button onClick={() => handleSort("members")}>
                <ArrowDropDownClose className={styles.arrowDropDownCloseSort} />
              </button>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedProjects.map((project, index) => (
            <tr
              style={{ cursor: "pointer" }}
              key={index}
              onClick={() => handleProjectSelect(project.id)}
            >
              <td className={styles.projectName}>{project.name}</td>
              <td>
                {project.start_date
                  ? new Date(project.start_date).toISOString().split("T")[0]
                  : ""}{" "}
                ~
                {project.end_date
                  ? new Date(project.end_date).toISOString().split("T")[0]
                  : ""}
              </td>
              <td>
                {project.modified_at
                  ? new Date(project.modified_at).toISOString().split("T")[0]
                  : "No Date Available"}
              </td>
              <td>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Member
                    members={project.members}
                    SingleMemberIcon={SingleMemberIcon}
                    MembersIcon={MembersIcon}
                  />
                  <span className={styles["members"]}>
                    {project.members.length}人
                  </span>
                </div>
              </td>
              <td>
                <div
                  className={styles.dropdownWrapper}
                  onMouseEnter={() => setDropdownOpen(index)}
                  onMouseLeave={() => setDropdownOpen(null)}
                >
                  <ContextMenu />
                  {dropdownOpen === index && ( // ホバー中に表示
                    <div className={styles.dropdownMenu}>
                      <ul>
                        <li
                          className={styles.danger}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenModal(project.id, project.name);
                          }}
                        >
                          削除{" "}
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isDeleteProjectModalOpen && selectedProject && (
        <DeleteProjectModal
          onClose={handleCloseModal}
          projectName={selectedProject.name}
          projectId={selectedProject.id}
        />
      )}
      {/* モーダルコンポーネント */}
      <Modal
        label={"プロジェクト新規作成"}
        isOpen={isModalOpen}
        onClose={closeModal}
        content={modalContent}
      />
    </div>
  );
};

export default ProjectList;
