import React, { useState, ReactNode } from "react";
import styles from "./index.module.scss";
import { ReactComponent as Close } from "../../images/close.svg";

interface TooltipProps {
  children: ReactNode;
}

const PermitDescriptionTooltip: React.FC<TooltipProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => {
    console.log("hrere");
    setIsVisible(false);
  };

  return (
    <div className={styles.tooltip} onClick={showTooltip}>
      {children}
      {isVisible && (
        <div className={styles.tooltip_box}>
          <div className={styles.tooltip_box_header}>
            <div className={styles.tooltip_box_header_text}>権限について</div>
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevents the click from propagating to the parent
                hideTooltip();
              }}
            >
              <Close />
            </div>
          </div>
          <div className={styles.tooltip_box_text}>
            <table className={styles.permissions_table}>
              <thead>
                <tr>
                  <th>権限</th>
                  <th>プロジェクト削除</th>
                  <th>生成</th>
                  <th>閲覧</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>オーナー</td>
                  <td>◯</td>
                  <td>◯</td>
                  <td>◯</td>
                </tr>
                <tr>
                  <td>編集者</td>
                  <td className={styles.cross_symbol}>×</td>
                  <td className={styles.cross_symbol}>×</td>
                  <td>◯</td>
                </tr>
                <tr>
                  <td>閲覧者</td>
                  <td className={styles.cross_symbol}>×</td>
                  <td className={styles.cross_symbol}>×</td>
                  <td className={styles.cross_symbol}>×</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PermitDescriptionTooltip;
