import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { RootState } from "../../store";
import { ReactComponent as ArrowDropDown } from "../../images/arrow-drop-down.svg";
import { useOrganization } from "./useOrganization";
import { UserDropdown } from "./UserDropdown";
import styles from "./index.module.scss";

const Header: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  
  const { currentUser, organization } = useSelector(
    (state: RootState) => state.auth
  );

  const { isLoading, error } = useOrganization(currentUser?.organization_id);

  // Show error toast if organization fetch fails
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <div className={styles.header}>
      <div className={styles.header__icons}>
        <div className={styles["icon-logo"]} onClick={() => navigate("/")} />
        <p onClick={handleLogoClick}>プロジェクト</p>
      </div>
      
      <nav>
        <div className={styles.header__icons}>
          <i className={styles["icon-user"]} />
          <div
            className={styles.displayNameOrg}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className={styles.displayNameOrg_container}>
              {currentUser?.display_name} <br />
              <span className={styles.displayNameOrg_orgName}>
                {isLoading ? "読み込み中..." : organization.name}
              </span>
            </div>
            <span className={styles.arrowDropDown}>
              <ArrowDropDown />
            </span>
          </div>

          <UserDropdown
            isOpen={isDropdownOpen}
            onClose={() => setIsDropdownOpen(false)}
          />
        </div>
      </nav>
    </div>
  );
};

export default Header;
