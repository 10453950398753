import { commonHeader, endpoints } from "../../config/apiConfig";
import { ErrorBoundary } from "../../errors/errorBoundary";
import { toast } from "react-toastify";

export class AuthApi {
  private getRequestInfo(method: string, requestBody?: object): RequestInit {
    return {
      method: method,
      headers: commonHeader,
      credentials: "include",
      keepalive: true,
      ...{
        body: JSON.stringify(requestBody),
      },
    };
  }

  private getRequestBody(baseBody: object, extraBody?: object): object {
    // ベースとなる共通データと、メソッドごとの追加データをマージ
    return { ...baseBody, ...extraBody };
  }

  async signin() {}

  async signout(): Promise<Response | null> {
    const signout = endpoints.logoutApp;

    try {
      const requestInfo = this.getRequestInfo("GET");
      const requestBody = this.getRequestBody(requestInfo);

      const response = await fetch(signout, requestBody);

      if (response.ok) {
        console.log("signout request is succeeded");
        return response;
      } else {
        const errorMessage = ErrorBoundary(response.status);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.log(error);
      toast.error("予期しないエラー");
      return null;
    }
  }
}
