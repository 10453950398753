import React from 'react';
import Button from "../button";
import styles from "./index.module.scss";

interface OrganizationFormProps {
  name: string;
  url: string;
  isSaving: boolean;
  onNameChange: (value: string) => void;
  onUrlChange: (value: string) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  name,
  url,
  isSaving,
  onNameChange,
  onUrlChange,
  onSave,
  onCancel,
}) => {
  return (
    <div>
      <form className={styles.content}>
        <div>
          <label>
            組織名
            <span style={{ color: "red", marginLeft: "4px", fontWeight: "bold" }}>*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
            required
          />
        </div>
        <div>
          <label>会社のURL（任意）</label>
          <input
            placeholder="詳細を入力してください。空欄可。"
            name="description"
            value={url}
            onChange={(e) => onUrlChange(e.target.value)}
          />
        </div>
      </form>
      <div className={styles.buttonContainer}>
        <Button
          label="キャンセル"
          styleType="white"
          onClick={onCancel}
        />
        <Button
          label="保存"
          styleType="blue"
          onClick={onSave}
          disabled={!name.trim() || isSaving}
        />
      </div>
    </div>
  );
};
